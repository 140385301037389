import React from 'react';
import * as Sentry from '@sentry/react';
import { Dialog, Divider, DialogTitle } from '@mui/material';
import { useMutation } from '@apollo/client';
import { useSnackbar } from 'src/components/v3/Snackbar';

import { DEFAULT_PAYMENT_METHODS } from '@legalsurf/common';

import ExpenseForm from 'src/forms/ExpenseForm';
import {
  ExpensesQuery,
  CreateExpenseMutation,
  UpdateExpenseMutation,
} from 'src/pages/Dashboard/Expenses/ExpensesQueries';
import { useStudioId } from 'src/utils/hooks/useStudioId';
import { useDefaultCurrency } from 'src/autocompletes/CurrencyAutocomplete';
import dayjs from 'dayjs';

const ExpenseDialog = ({
  expense,
  open,
  onClose,
  title,
  type,
  entity,
  filecase,
  ...rest
}) => {
  const { currency: defaultCurrency } = useDefaultCurrency();
  const studioId = useStudioId();
  const { openSnackbar } = useSnackbar();
  const [createExpense, { loading }] = useMutation(CreateExpenseMutation, {
    update: (cache, response) => {
      cache.modify({
        id: cache.identify(filecase),
        fields: {
          expenses(expenses, { toReference }) {
            return [
              ...expenses,
              toReference(response.data.createExpense, true),
            ];
          },
        },
      });
    },
    refetchQueries: [ExpensesQuery, 'ExpensesQuery'],
  });
  const [updateExpense] = useMutation(UpdateExpenseMutation);

  const handleSubmit = async (values, formikBag) => {
    const changeMutation = expense ? updateExpense : createExpense;

    const total = values.items.reduce((acc, item) => acc + item.amount, 0);

    try {
      await changeMutation({
        variables: {
          studio: studioId,
          currency:
            typeof values?.currency === 'string'
              ? values?.currency
              : values?.currency?.value,
          amount: total,
          date: new Date(values.date).toISOString(),
          concept: values.concept,
          items: values.items,

          entity: values?.entity?.value,
          filecase: values?.filecase?.value,
          responsible: values?.responsible?.value,
          canBeSeenByCreatorOnly: values.canBeSeenByCreatorOnly,
          expenseId: expense ? expense.id : undefined,
          tags: values?.tags?.map((tag) => tag?.value ?? tag.label),

          comments:
            values.comment && values.comment !== '<p><br></p>'
              ? [values.comment]
              : undefined,
          payments:
            values.shouldPay && values.paid > 0
              ? [
                  {
                    amount: values.paid,
                    concept: 'Pagado',
                  },
                ]
              : undefined,
        },
      });

      openSnackbar('Gasto creado exitosamente.', {
        severity: 'success',
      });

      onClose();

      formikBag.resetForm({
        values: undefined,
      });
    } catch (error) {
      Sentry.captureException(error);
      openSnackbar('Hubo un error. Intente mas tarde.', {
        severity: 'error',
      });
    }
  };

  const initialValues = expense
    ? {
        ...expense,
        date: dayjs(expense.date),
        items: expense.items?.length
          ? expense.items.map((item) => ({
              description: item.description,
              amount: item.amount,
            }))
          : [],
        currency: expense.currency
          ? {
              label: expense.currency.name,
              value: expense.currency.name,
            }
          : null,
        entity: expense?.entity
          ? {
              label: expense.entity.displayName,
              value: expense.entity.id,
            }
          : null,
        filecase: expense?.filecase
          ? {
              label: expense.filecase.title,
              value: expense.filecase.id,
            }
          : null,
        responsible: expense?.responsible
          ? {
              label: expense.responsible.name,
              value: expense.responsible.id,
            }
          : null,
        tags: expense.tags
          ? expense.tags.map((tag) => ({
              label: tag.name,
              value: tag.name,
            }))
          : [],
        paymentMethod: DEFAULT_PAYMENT_METHODS.find(
          (transac) => transac.value === expense.paymentMethod,
        ),
      }
    : {
        canBeSeenByCreatorOnly: false,
        date: dayjs(),
        responsible: null,
        tags: [],
        paymentMethod: null,
        entity: entity?.id
          ? {
              label: entity?.displayName,
              value: entity?.id,
            }
          : null,
        filecase: filecase?.id
          ? {
              label: filecase?.title,
              value: filecase?.id,
            }
          : null,
        currency: defaultCurrency
          ? {
              label: defaultCurrency?.name,
              value: defaultCurrency?.name,
            }
          : null,
      };

  return (
    <Dialog open={open} onClose={loading ? undefined : onClose} {...rest}>
      <DialogTitle>{title}</DialogTitle>

      <Divider />

      <ExpenseForm
        enableReinitialize
        buttonLabel={expense ? 'Editar' : 'Guardar'}
        initialValues={initialValues}
        isEdit={!!expense}
        type={type}
        filecase={filecase}
        onCancel={onClose}
        onSubmit={handleSubmit}
      />
    </Dialog>
  );
};

export default ExpenseDialog;
