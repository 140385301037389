import React, { useState } from 'react';
import { Box, Stack, darken, Typography, Tooltip } from '@mui/material';
import { Link } from 'react-router-dom';

import {
  LS_PERMISSIONS_MAP,
  WhatsappContactLegalSurfLink,
} from '@legalsurf/common';
import { useStudioMembers } from '@legalsurf/hooks';
import PermissionGuard from 'src/components/v2/PermissionGuard';
import Page from 'src/components/v2/Page';
import oneDriveIcon from 'src/pages/Dashboard/Settings/onedrive.svg';
import googelCalendarIcon from 'src/assets/images/googlecalendar.png';

import { useStudioId } from 'src/utils/hooks/useStudioId';
import {
  LSContracts,
  LSFilecase,
  LSWhatsappIcon,
  LSWorkflowIcon,
} from 'src/components/icons';
import SettingOption from './SettingOption';

import SettingsSection from './SettingsSection';
import googleDriveIcon from './logo_drive_2020q4_color_2x_web_64dp.png';
import { StudioDataSettingOption } from './StudioDataSettingOption';
import { ManageMembersModal } from 'src/popovers/ManageMembersPopover';

const Settings = () => {
  const studioId = useStudioId();

  const { data: members } = useStudioMembers({
    variables: {
      studio: studioId,
    },
  });

  const [anchorManageMembersEl, setAnchorManageMembersEl] = useState(null);

  const handleOpenInvitationsPopover = (event) => {
    setAnchorManageMembersEl(event.currentTarget);
  };

  const handleCloseManageMembersPopover = () => {
    setAnchorManageMembersEl(null);
  };

  const listOfOptions = [
    {
      id: 2,
      subtitle: 'Gestiona aquí tus usuarios y permisos.',
      onClick: handleOpenInvitationsPopover,
      background: '#AF52F9',
      buttonProps: {
        sx: {
          backgroundColor: '#4D256C',

          '&:hover': {
            backgroundColor: darken('#4D256C', 0.1),
          },
        },
      },
      image: members.map(({ picture }) => picture),
      buttonLabel: 'Administrar usuarios y permisos',
      permissions: [LS_PERMISSIONS_MAP.CONFIG.ACCESS_ACCOUNTS_AND_PERMISSIONS],
    },
    {
      id: 3,
      title: 'Mensajes de WhatsApp',
      subtitle:
        'Crea mensajes personalizadas de Whatsapp y mejora la comunicación con clientes',
      to: `/dashboard/${studioId}/settings/templates`,
      // permissions: [LS_PERMISSIONS_MAP.CONFIG.ACCESS_NOTIFICATION_TEMPLATES],
      icon: <LSWhatsappIcon fontSize="large" />,
      background: '#35C4AB',
      buttonLabel: 'Administrar mensajes',
      buttonProps: {
        sx: {
          backgroundColor: '#288675',
          '&:hover': {
            backgroundColor: darken('#288675', 0.5),
          },
        },
      },
    },
    {
      id: 4,
      title: 'Flujos de trabajo',
      subtitle: 'Automatiza procesos y tareas con flujos de trabajo.',
      to: `/dashboard/${studioId}/settings/workflows`,
      icon: <LSWorkflowIcon color="red" fontSize="large" />,
      permissions: [LS_PERMISSIONS_MAP.CONFIG.ACCESS],
      background: '#5238F1',
      buttonLabel: 'Administrar flujos',
      buttonProps: {
        sx: {
          backgroundColor: '#302289',

          '&:hover': {
            backgroundColor: darken('#302289', 0.5),
          },
        },
      },
    },
    {
      id: 5,
      title: 'Plantillas de escritos',
      subtitle:
        'Crea modelos y plantillas para automatizar la generación de documentación.',
      to: `/dashboard/${studioId}/settings/contracts`,
      icon: <LSContracts fontSize="large" />,
      // permissions: [LS_PERMISSIONS_MAP.CONFIG.ACCESS_NOTIFICATION_TEMPLATES],
      background: 'rgba(97, 103, 255, 1)',
      buttonLabel: 'Administrar plantillas',
      buttonProps: {
        sx: {
          backgroundColor: darken('rgba(97, 103, 255, 1)', 0.4),

          '&:hover': {
            backgroundColor: darken('rgba(97, 103, 255, 1)', 0.5),
          },
        },
      },
    },
    {
      id: 6,
      title: 'Campos personalizados',
      subtitle:
        'Crea campos y datos personalizados para tus expedientes y clientes.',
      to: `/dashboard/${studioId}/settings/category?tab=customFields`,
      icon: <LSFilecase color="red" fontSize="large" />,
      // permissions: [LS_PERMISSIONS_MAP.CONFIG.ACCESS_NOTIFICATION_TEMPLATES],
      background: '#5FA8EB',
      buttonLabel: 'Campos personalizados',
      buttonProps: {
        sx: {
          backgroundColor: '#346DA1',

          '&:hover': {
            backgroundColor: darken('#346DA1', 0.5),
          },
        },
      },
    },
  ];

  const integrations = [
    {
      title: 'Google Calendar',
      href: `/dashboard/${studioId}/settings/googlecalendar`,
      image: googelCalendarIcon,
      permissions: [LS_PERMISSIONS_MAP.CALENDAR.ACCESS],
    },
    {
      title: 'Google Drive',
      href: `/dashboard/${studioId}/settings/googledrive`,
      image: googleDriveIcon,
      permissions: [LS_PERMISSIONS_MAP.DOCUMENTS.ACCESS],
    },
    {
      title: 'Microsoft OneDrive',
      href: '#',
      image: oneDriveIcon,
      permissions: [LS_PERMISSIONS_MAP.DOCUMENTS.ACCESS],
      tooltip:
        'Podras conectar tu cuenta de OneDrive directamente en la seccion de Documentos o Expedientes.',
    },
  ];

  const customized = [
    {
      title: 'Etiquetas',
      href: `/dashboard/${studioId}/settings/category`,
      permissions: [LS_PERMISSIONS_MAP.CONFIG.ACCESS_CATEGORIES],
    },
    {
      title: 'Monedas',
      href: `/dashboard/${studioId}/settings/category?tab=currencies`,
      permissions: [
        LS_PERMISSIONS_MAP.CONFIG.ACCESS_CATEGORIES,
        LS_PERMISSIONS_MAP.SALES.READ,
      ],
    },
    {
      title: 'Expedientes',
      href: `/dashboard/${studioId}/settings/category?tab=filecaseStates`,
      permissions: [
        LS_PERMISSIONS_MAP.CONFIG.ACCESS_CATEGORIES,
        LS_PERMISSIONS_MAP.FILECASES.READ,
      ],
    },
    {
      title: 'Directorios',
      href: `/dashboard/${studioId}/settings/category?tab=directoryStates`,
      permissions: [
        LS_PERMISSIONS_MAP.CONFIG.ACCESS_CATEGORIES,
        LS_PERMISSIONS_MAP.DIRECTORY.ACCESS,
      ],
    },
    {
      title: 'Importación de datos',
      subtitle: 'Trae tus datos masivamente con un par de clicks.',
      href: `/dashboard/${studioId}/settings/imports`,
    },
  ];

  return (
    <Page
      sx={{
        mx: 'auto',
      }}
      title="Configuración"
    >
      <Stack direction={['column', 'column', 'row']} gap={2} p={[2, 2, 2, 0]}>
        <Stack direction="column" flex={1} gap={2}>
          <PermissionGuard
            permissions={LS_PERMISSIONS_MAP.CONFIG.ACCESS_STUDIO_DATA}
          >
            <StudioDataSettingOption />
          </PermissionGuard>

          <PermissionGuard
            permissions={
              LS_PERMISSIONS_MAP.CONFIG.ACCESS_ACCOUNTS_AND_PERMISSIONS
            }
          >
            <ManageMembersModal
              open={Boolean(anchorManageMembersEl)}
              onClose={handleCloseManageMembersPopover}
              onSubmit={handleCloseManageMembersPopover}
            />
          </PermissionGuard>

          {listOfOptions.slice(0, 2).map((option) => (
            <PermissionGuard key={option.id} permissions={option.permissions}>
              <SettingOption {...option} />
            </PermissionGuard>
          ))}
        </Stack>

        <Stack direction="column" flex={1} gap={2}>
          {listOfOptions.slice(2, 5).map((option) => (
            <PermissionGuard key={option.id} permissions={option.permissions}>
              <SettingOption {...option} />
            </PermissionGuard>
          ))}
        </Stack>

        <Stack direction="column" flex={1} gap={2} pl={2} pt={1}>
          <PermissionGuard
            permissions={integrations.map((item) => item.permissions).flat()}
          >
            <SettingsSection title="Integraciones">
              {integrations.map((item) => {
                const content = (
                  <Stack
                    alignItems="center"
                    direction="row"
                    gap={1}
                    key={item.href}
                  >
                    <Box
                      component="img"
                      src={item.image}
                      sx={{ width: 24, height: 24 }}
                    />

                    <Typography
                      color="textSecondary"
                      component={Link}
                      key={item.title}
                      to={item.href}
                    >
                      {item.title}
                    </Typography>
                  </Stack>
                );

                if (item.tooltip) {
                  return (
                    <PermissionGuard
                      key={item.title}
                      permissions={item.permissions}
                    >
                      <Tooltip title={item.tooltip}>{content}</Tooltip>
                    </PermissionGuard>
                  );
                }

                return (
                  <PermissionGuard
                    key={item.title}
                    permissions={item.permissions}
                  >
                    {content}
                  </PermissionGuard>
                );
              })}
            </SettingsSection>
          </PermissionGuard>

          <SettingsSection title="Soporte">
            <Typography
              color="textSecondary"
              component="a"
              href="https://calendly.com/legalsurf-ventas/asesoria-onboarding"
              rel="noreferrer"
              sx={{ underline: 'none' }}
              target="_blank"
            >
              Agenda una reunion gratuita
            </Typography>

            <Typography
              color="textSecondary"
              component="a"
              href={WhatsappContactLegalSurfLink}
              rel="noreferrer"
              sx={{ underline: 'none' }}
              target="_blank"
            >
              Habla con nostros por WhatsApp
            </Typography>
          </SettingsSection>

          <PermissionGuard
            permissions={customized.map((item) => item.permissions).flat()}
          >
            <SettingsSection title="Personaliza tu estudio">
              {customized.map((item) => (
                <PermissionGuard
                  key={item.title}
                  all
                  permissions={item.permissions}
                >
                  <Typography
                    color="textSecondary"
                    component={Link}
                    to={item.href}
                  >
                    {item.title}
                  </Typography>
                </PermissionGuard>
              ))}
            </SettingsSection>
          </PermissionGuard>
        </Stack>
      </Stack>
    </Page>
  );
};

export default Settings;
