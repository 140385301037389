import React from 'react';
import {
  Grid,
  Button,
  DialogContent,
  DialogActions,
  FormControl,
  AccordionDetails,
  AccordionSummary,
  Accordion,
  Stack,
  Typography,
} from '@mui/material';
import { Formik } from 'formik';

import Form, {
  CustomFields,
  SelectField,
  SubmitButton,
  TextField,
} from 'src/components/ui-components/Form';
import {
  AssigneesAutocomplete,
  EntityStatusAutocomplete,
  TagsAutocomplete,
} from 'src/autocompletes';
import { LSDirectory as LSDirectoryIcon } from 'src/components/icons';

import { entityTypes } from '@legalsurf/common';

import DirectoryFormValidationSchema from './validation-schema';
import { ExpandLess } from '@mui/icons-material';
import { CreationInfoBanner } from 'src/components/v3/CreationInfoBanner';
import { useCustomFields } from 'src/dialogs/CustomFieldFormDialog/utils';
import { useDialog } from 'src/dialogs/Dialogs';

const deprecatedEntityFields = [
  {
    name: 'location',
    label: 'Estado / Provincia',
  },
  {
    name: 'city',
    label: 'Ciudad',
  },
  {
    name: 'departament',
    label: 'Piso / Departamento',
  },
  {
    name: 'postalCode',
    label: 'Codigo postal',
  },
];

const getEnabledDeprecatedFields = (entity) => {
  return deprecatedEntityFields.filter((field) => !!entity[field.name]);
};

const DirectoryForm = ({
  onSubmit,
  onCancel,
  initialValues,
  entity,
  ...props
}) => {
  const { customFields } = useCustomFields();
  const dispatchCustomFieldFormDialog = useDialog('customFieldForm');

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={DirectoryFormValidationSchema}
      onSubmit={onSubmit}
      {...props}
    >
      {({ values, setFieldValue, resetForm }) => (
        <>
          <DialogContent>
            <Form id="directoryForm">
              <FormControl item component={Grid} xs={12}>
                <Grid container item spacing={2}>
                  <Grid item xs={6}>
                    <Button
                      fullWidth
                      color="primary"
                      startIcon={<LSDirectoryIcon />}
                      sx={{
                        padding: 3,
                        fontWeight: '600',
                        opacity: !values.isLegalEntity ? 1 : 0.4,
                      }}
                      variant="outlined"
                      onClick={() => {
                        resetForm();
                        setFieldValue('isLegalEntity', false);
                      }}
                    >
                      Persona
                    </Button>
                  </Grid>

                  <Grid item xs={6}>
                    <Button
                      fullWidth
                      color="primary"
                      startIcon={<LSDirectoryIcon type="organization" />}
                      sx={{
                        padding: 3,
                        fontWeight: '600',
                        opacity: values.isLegalEntity ? 1 : 0.4,
                      }}
                      variant="outlined"
                      onClick={() => {
                        resetForm();
                        setFieldValue('isLegalEntity', true);
                      }}
                    >
                      Organización
                    </Button>
                  </Grid>
                </Grid>
              </FormControl>

              {!values.isLegalEntity ? (
                <>
                  <TextField
                    gridProps={{ xs: 12, md: 6 }}
                    label="Nombre"
                    name="firstname"
                    placeholder="Nombre"
                  />
                  <TextField
                    gridProps={{ xs: 12, md: 6 }}
                    label="Apellido"
                    name="lastname"
                    placeholder="Apellido"
                  />
                </>
              ) : (
                <TextField
                  gridProps={{ xs: 12 }}
                  label="Nombre"
                  name="businessname"
                  placeholder="Nombre"
                />
              )}

              <SelectField
                gridProps={{ xs: 12, md: 6 }}
                label="Tipo"
                name="type"
                options={entityTypes}
              />

              <EntityStatusAutocomplete
                gridProps={{ xs: 12, md: 6 }}
                creatable
                name="state"
                placeholder="Ej. Contactado/Negociando"
              />

              <TagsAutocomplete
                gridProps={{ xs: 12, md: 6 }}
                creatable
                multiple
                name="tags"
                placeholder="Ej. Zona sur"
              />

              <AssigneesAutocomplete
                gridProps={{ xs: 12, md: 6 }}
                multiple
                name="assigned"
                placeholder="Ej. Nicolas"
              />

              <Grid item xs={12}>
                <Accordion
                  disableGutters
                  elevation={0}
                  sx={{
                    border: '1px solid',
                    borderColor: 'primary.main',
                    borderStyle: 'dashed',
                    borderRadius: 1,
                    '&::before': { display: 'none' },
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandLess color="primary" />}
                    color="red"
                  >
                    <Stack>
                      <Typography
                        fontWeight="bold"
                        fontSize={18}
                        color="primary"
                      >
                        + información adicional
                      </Typography>

                      <Typography
                        fontWeight="400"
                        fontSize={15}
                        color="primary"
                      >
                        Detalles de contacto adicionales
                      </Typography>
                    </Stack>
                  </AccordionSummary>

                  <AccordionDetails>
                    <Grid container spacing={2}>
                      <TextField
                        multiline
                        maxRows={4}
                        label="Descripción"
                        name="description"
                        placeholder="Profesional independiente, ingeniero civil, busca asesoría legal para tramitar herencia familiar. Interesado en resolución rápida y confidencial. Disponible tardes y fines de semana..."
                        // placeholder={[
                        //   'Emprendedor del rubro gastronómico necesita regularización de documentación para su restaurante. Urgente resolver tema de habilitaciones municipales. Requiere enfoque proactivo y rápido...',
                        //   'Empresa líder en tecnología, 5 años en el mercado. Requiere asesoramiento legal en contratos comerciales y expansión internacional. Contacto principal: María Gómez, Directora de Operaciones...',
                        //   'Profesional independiente, ingeniero civil, busca asesoría legal para tramitar herencia familiar. Interesado en resolución rápida y confidencial. Disponible tardes y fines de semana...',
                        // ]}
                      />

                      <TextField
                        gridProps={{ xs: 12, md: 6 }}
                        label="Identificador"
                        name="identification"
                      />

                      <TextField
                        gridProps={{ xs: 12, md: 6 }}
                        label="Telefono"
                        name="phone"
                        placeholder="+52 1 80 1234 5678"
                      />
                      <TextField
                        gridProps={{ xs: 12, md: 6 }}
                        label="Email"
                        name="email"
                      />
                      <TextField
                        gridProps={{ xs: 12, md: 6 }}
                        label="Pais"
                        name="country"
                        placeholder="Ej. Colombia"
                      />

                      <TextField label="Direccion" name="address" />

                      {!!entity &&
                        getEnabledDeprecatedFields(entity).map((field) => (
                          <TextField
                            key={field.name}
                            label={field.label}
                            name={field.name}
                          />
                        ))}
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Grid>

              {!!entity && (
                <Grid item xs={12}>
                  <Accordion
                    disableGutters
                    elevation={0}
                    sx={{
                      border: '1px solid',
                      borderColor: 'primary.main',
                      borderStyle: 'dashed',
                      borderRadius: 1,
                      '&::before': { display: 'none' },
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandLess color="primary" />}
                      color="red"
                    >
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        width="100%"
                      >
                        <Stack>
                          <Typography
                            fontWeight="bold"
                            fontSize={18}
                            color="primary"
                          >
                            + Campos personalizados
                          </Typography>

                          <Typography
                            fontWeight="400"
                            fontSize={15}
                            color="primary"
                          >
                            Personaliza tu información
                          </Typography>
                        </Stack>

                        <Button
                          variant="text"
                          onClick={(e) => {
                            e.stopPropagation();
                            dispatchCustomFieldFormDialog('EntityForm', {
                              onEntities: true,
                            });
                          }}
                        >
                          Crear campo
                        </Button>
                      </Stack>
                    </AccordionSummary>

                    <AccordionDetails>
                      {customFields?.length ? (
                        <Grid container spacing={2}>
                          <CustomFields
                            modelId={entity.id}
                            modelType="Entity"
                          />
                        </Grid>
                      ) : (
                        <CreationInfoBanner
                          buttonStr="Crear campo"
                          description="Crea campos y datos personalizados para tus expedientes y clientes."
                          title="Campos Personalizados"
                          onClick={() =>
                            dispatchCustomFieldFormDialog('EntityForm', {
                              onEntities: true,
                            })
                          }
                        />
                      )}
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              )}
            </Form>
          </DialogContent>

          <DialogActions sx={{ gap: 6, justifyContent: 'center' }}>
            <Button sx={{ p: 2 }} onClick={onCancel}>
              Cancelar
            </Button>

            <SubmitButton
              form="directoryForm"
              sx={{ p: 2, px: 5 }}
              type="submit"
            >
              {entity ? 'Actualizar' : 'Crear'}
            </SubmitButton>
          </DialogActions>
        </>
      )}
    </Formik>
  );
};

export default DirectoryForm;
