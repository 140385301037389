/* eslint-disable react/display-name */
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Fade,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import React, { useRef } from 'react';
import { useUser } from 'src/utils/hooks/useUser';
import html2canvas from 'html2canvas';

import wrappedPageHeroBg from './wrapped-page-hero-bg.png';
import userRankingCardBg from './user-ranking-card-bg.png';
import userMainMetricCardBg from './user-main-metric-card-bg.png';
import userMetricsSummaryCardBg from './user-metrics-summary-card-bg.png';
import legalsurfLogo from '../../assets/images/logo.svg';
import socialMediaIcons from './social-media-icons.png';
import { gql, useQuery } from '@apollo/client';
import { OrganizationSwitcher, useOrganization } from '@clerk/clerk-react';

import countries from 'src/i18n/locales/countries.es.json';
import { ArrowBack, Download } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useBreakpoints } from 'src/utils/hooks/common';

const cardWidth = 271;

const StudioWrapped2024Query = gql`
  query StudioWrapped2024Query($studioId: ID!) {
    studio(id: $studioId) {
      id
      name
      picture

      wrapped2024
      address {
        id
        country
      }
    }
  }
`;

const CardAvatar = ({ src, sx, ...props }) => (
  <Box
    sx={{
      backgroundImage: `url(${src})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      borderRadius: '50%',
      width: 110,
      height: 110,
      ...sx,
    }}
    {...props}
  />
);

const useWrapped2024 = () => {
  const { organization, isLoaded } = useOrganization();

  const { data, loading } = useQuery(StudioWrapped2024Query, {
    variables: {
      studioId: organization?.id,
    },
    fetchPolicy: 'cache-first',
    skip: !organization?.id,
  });

  return { loading: !isLoaded || loading, studio: data?.studio };
};

const handleDownloadImage = async (event, name = 'resumen legalsurf 2024') => {
  const canvas = await html2canvas(event, {
    useCORS: true,
    scale: 2,
    backgroundColor: '#171c20',
  });
  const data = canvas.toDataURL('image/png');
  const link = document.createElement('a');

  link.href = data;
  link.download = `${name}.png`;

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const handleShareImage = async (
  event,
  name = 'resumen legalsurf 2024',
  options = { isTablet: false },
) => {
  if (options?.isTablet) {
    return handleDownloadImage(event, name);
  }

  try {
    // Create canvas and convert to blob
    const canvas = await html2canvas(event, { useCORS: true, scale: 2 });
    const blob = await (await fetch(canvas.toDataURL('image/png'))).blob();

    // Check if Web Share API is supported
    if (navigator.share && navigator.canShare) {
      const file = new File([blob], `${name}.png`, { type: 'image/png' });

      // Check if the file can be shared
      if (navigator.canShare({ files: [file] })) {
        await navigator.share({
          title: name,
          text: 'Compartiendo mi resumen de productividad legal 2024 con @legalsurf - ¡Conoce las principales métricas de mi desempeño!',
          files: [file],
        });

        return true;
      }
    }

    // Fallback to download if Web Share API is not available
    return handleDownloadImage(event, name);
  } catch (error) {
    if (/Share canceled/.test(error.message)) {
      return;
    }

    console.error('Error sharing image:', { error });
    // Fallback to download if sharing fails
    return handleDownloadImage(event, name);
  }
};

// attempt to get only a single name because fullname is provided
const formatName = (name) => {
  if (!name) {
    return name;
  }

  const splitName = name.split(' ');

  if (splitName[0].length > 2) {
    return splitName[0];
  }

  return name;
};

const Wrapped2024Footer = ({ fontSize = 20, ...props }) => {
  return (
    <Stack direction="row" alignItems="center" gap={0.5} {...props}>
      <Typography color="#A39A9B" fontSize={fontSize} fontWeight={400}>
        Tu resumen 2024
      </Typography>

      <Box
        component="img"
        src={legalsurfLogo}
        alt="LegalSurf"
        height={fontSize + 2}
      />
    </Stack>
  );
};

const UserRankingCard = React.forwardRef((props, ref) => {
  const { user } = useUser();
  const { studio } = useWrapped2024();

  const userCountry = studio?.address?.country || user?.country;
  const formatedCountry =
    userCountry?.length === 2
      ? countries.find(
          (country) =>
            country?.code?.toLowerCase() === userCountry?.toLowerCase(),
        )?.name_en || userCountry
      : userCountry;

  return (
    <Box
      ref={ref}
      sx={{
        height: 615,
        width: cardWidth,
        backgroundImage: 'url(' + userRankingCardBg + ')',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        position: 'relative',
        overflow: 'hidden',

        borderRadius: 2,
        border: '1px solid',
        borderColor: '#E5AB8A',
      }}
    >
      <CardAvatar
        sx={{
          position: 'absolute',
          top: 66,
          right: 85,
          left: 85,
        }}
        src={user.picture}
      />

      <Stack
        sx={{
          position: 'absolute',
          width: 240,
          p: 2,
          right: 0,
          top: 200,
          bgcolor: 'black',
        }}
        gap={1}
      >
        <Stack gap={0.5}>
          <Typography
            color="white"
            fontWeight={600}
            fontSize={26}
            lineHeight={1}
          >
            {formatName(user?.name)}
          </Typography>

          <Typography
            color="white"
            fontWeight={600}
            fontSize={14}
            lineHeight={1}
            sx={{ opacity: 0.9 }}
          >
            / {studio.name}
          </Typography>
        </Stack>

        <Typography
          color="#CAFD72"
          fontWeight={600}
          fontSize={70}
          lineHeight={1}
        >
          #{studio.wrapped2024.ranking}
        </Typography>

        <Typography
          color="white"
          fontWeight={600}
          fontSize={30}
          lineHeight={0.9}
        >
          Ranking {formatedCountry}
        </Typography>

        {studio.wrapped2024.ranking < 500 && (
          <Typography color="white" fontSize={14} lineHeight={1}>
            Te encuentras en el top 500 de despachos más activos de tu país
          </Typography>
        )}
      </Stack>
    </Box>
  );
});

const mainMatricsKeysToI18n = {
  filecases: 'Expedientes',
  tasks: 'Tareas',
  entries: 'Actuaciones',
};

const StudioSwitcher = () => (
  <OrganizationSwitcher
    skipInvitationScreen
    createOrganizationMode="navigation"
    hideSlug
    hidePersonal
    appearance={{
      elements: {
        organizationPreviewSecondaryIdentifier: {
          display: 'none',
        },
        notificationBadge: {
          display: 'none',
        },
        organizationSwitcherPopoverInvitationActions: {
          display: 'none',
        },
        organizationSwitcherPopoverActionButton__createOrganization: {
          display: 'none',
        },
        organizationSwitcherPopoverActionButton__manageOrganization: {
          display: 'none',
        },
        organizationSwitcherTrigger__open: {
          color: 'white',
        },
        organizationSwitcherTrigger: {
          color: 'white',
        },
      },
    }}
  />
);

const ShareButton = ({ onClick, onDownloadClick }) => {
  const { isTablet } = useBreakpoints();

  return (
    <Stack direction="row" gap={1} alignItems="center" mx="auto">
      <Button
        onClick={onClick}
        sx={{
          color: 'white',
          textTransform: 'none',
          transition: 'all 0.2s ease-in-out',
          borderRadius: 2,
          border: '1px solid',
          py: 1.5,
          px: 2,
          '&:hover': {
            background: 'linear-gradient(170deg, #F0973B, #EA427E, #9531CA)',
            transform: 'scale(1.01)',
          },
        }}
        endIcon={
          isTablet ? (
            <Download color="background" />
          ) : (
            <Box
              component="img"
              src={socialMediaIcons}
              alt="social media share"
              height={14}
            />
          )
        }
      >
        Compartir
      </Button>

      {onDownloadClick && !isTablet && (
        <Box>
          <IconButton onClick={onDownloadClick} color="background">
            <Download color="background" />
          </IconButton>
        </Box>
      )}
    </Stack>
  );
};

const UserMainMetricCard = React.forwardRef((props, ref) => {
  const { user } = useUser();
  const { studio } = useWrapped2024();

  return (
    <Box
      ref={ref}
      sx={{
        height: 615,
        width: cardWidth,
        backgroundImage: 'url(' + userMainMetricCardBg + ')',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        position: 'relative',
        overflow: 'hidden',

        borderRadius: 2,
        border: '1px solid',
        borderColor: '#E8FA00',
      }}
    >
      <Stack
        sx={{
          position: 'absolute',
          top: 100,
          left: 50,
        }}
        gap={3}
      >
        <CardAvatar
          src={user.picture}
          sx={{
            border: '3px solid',
            borderColor: 'primary.main',
            width: 75,
            height: 75,
          }}
        />

        <Stack>
          <Stack gap={0.5}>
            <Typography
              color="primary.main"
              fontWeight={600}
              fontSize={26}
              lineHeight={1}
            >
              {formatName(user?.name)}
            </Typography>

            <Typography
              color="#171C21"
              fontWeight={600}
              fontSize={14}
              lineHeight={1}
              pr={4}
            >
              Tu mayor métrica de productividad legal del 2024
            </Typography>
          </Stack>

          <Typography
            mt={2}
            color="primary.main"
            fontWeight={600}
            fontSize={70}
            lineHeight={1}
          >
            {studio.wrapped2024.metrics[studio.wrapped2024.primaryMetric]}
          </Typography>

          <Typography
            color="primary.main"
            fontWeight={600}
            fontSize={30}
            lineHeight={0.9}
          >
            {mainMatricsKeysToI18n[studio.wrapped2024.primaryMetric]}
          </Typography>
        </Stack>
      </Stack>
    </Box>
  );
});

const UserMetricsSummaryCard = React.forwardRef((props, ref) => {
  const { user } = useUser();
  const { studio } = useWrapped2024();

  return (
    <Box
      ref={ref}
      sx={{
        height: 621,
        width: cardWidth,
        backgroundImage: 'url(' + userMetricsSummaryCardBg + ')',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        position: 'relative',
        overflow: 'hidden',

        borderRadius: 2,
        border: '1px solid',
        borderColor: '#6D4FF6',
      }}
    >
      <Stack
        sx={{
          position: 'absolute',
          top: 44,
          left: 44,
        }}
        gap={1}
      >
        <Stack direction="row" gap={1}>
          <Stack>
            <Typography
              color="#CAFD72"
              fontWeight={600}
              fontSize={70}
              lineHeight={1}
            >
              20
            </Typography>

            <Typography
              color="#CAFD72"
              fontWeight={600}
              fontSize={70}
              lineHeight={1}
            >
              24
            </Typography>
          </Stack>

          <Stack gap={1} alignItems="center" justifyContent="center">
            <CardAvatar
              src={user.picture}
              sx={{
                width: 80,
                height: 80,
                boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
                borderRadius: 3,
              }}
            />

            <Typography
              color="white"
              fontWeight={600}
              fontSize={18}
              lineHeight={1}
            >
              {formatName(user?.name)}
            </Typography>
          </Stack>
        </Stack>

        <Stack>
          <Typography
            color="white"
            mb={2}
            fontWeight={600}
            fontSize={14}
            lineHeight={1}
            pr={4}
          >
            Estas son tus principales métricas de productividad del 2024
          </Typography>

          <Typography
            color="#CAFD72"
            fontWeight={600}
            fontSize={70}
            lineHeight={1}
          >
            {studio.wrapped2024.metrics.members}
          </Typography>

          <Typography color="white" fontWeight={600} fontSize={18}>
            Colaboradores
          </Typography>

          <Typography
            color="#CAFD72"
            fontWeight={600}
            fontSize={70}
            lineHeight={1}
          >
            {studio.wrapped2024.metrics.filecases}
          </Typography>

          <Typography color="white" fontWeight={600} fontSize={18}>
            Expedientes
          </Typography>

          <Typography
            color="#CAFD72"
            fontWeight={600}
            fontSize={70}
            lineHeight={1}
          >
            {studio.wrapped2024.metrics.entries}
          </Typography>

          <Typography color="white" fontWeight={600} fontSize={18}>
            Actuaciones
          </Typography>
        </Stack>
      </Stack>
    </Box>
  );
});

const ShareUserRanking = ({ onClick, onDownloadClick }) => {
  const { studio } = useWrapped2024();

  return (
    <Stack
      sx={{
        p: [1, 3, 3, 3],
        py: [1, 4, 8, 8],
        width: cardWidth,
      }}
      gap={3}
    >
      <Stack>
        <Typography
          color="#CAFD72"
          fontWeight={600}
          fontSize={90}
          lineHeight={1}
        >
          #{studio.wrapped2024.ranking}
        </Typography>

        <Typography
          color="white"
          fontWeight={600}
          fontSize={60}
          lineHeight={0.9}
        >
          Tu ranking
          <Box component="span" sx={{ color: 'primary.main' }}>
            .
          </Box>
        </Typography>
      </Stack>

      {studio.ranking < 500 && (
        <Typography color="white" fontSize={20} fontWeight={400} lineHeight={1}>
          Te encuentras en el{' '}
          <Box component="span" color="#CAFD72">
            top 500
          </Box>{' '}
          de despachos más activos de tu país
        </Typography>
      )}

      <ShareButton onClick={onClick} onDownloadClick={onDownloadClick} />

      <Wrapped2024Footer mx="auto" fontSize={14} />
    </Stack>
  );
};

const ShareMainMetric = ({ onClick, onDownloadClick }) => {
  return (
    <Stack
      sx={{
        p: [1, 3, 3, 3],
        py: [1, 4, 8, 8],
        width: cardWidth,
      }}
      gap={3}
    >
      <Typography color="#CAFD72" fontWeight={600} fontSize={70} lineHeight={1}>
        ¡Wow!
      </Typography>

      <Typography color="white" fontSize={20} fontWeight={400} lineHeight={1}>
        <Box component="span" color="#93F9FA">
          Aquí hay algo grande.
        </Box>
        Tu principal métrica del 2024 con LegalSurf
      </Typography>

      <ShareButton onClick={onClick} onDownloadClick={onDownloadClick} />

      <Wrapped2024Footer mx="auto" fontSize={14} />
    </Stack>
  );
};

const ShareSummaryMetrics = ({ onClick, onDownloadClick }) => {
  return (
    <Stack
      sx={{
        p: [1, 3, 3, 3],
        py: [1, 4, 8, 8],
        width: cardWidth,
      }}
      gap={3}
    >
      <Typography color="#93F9FA" fontWeight={600} fontSize={70} lineHeight={1}>
        ¡Gran 2024!
      </Typography>

      <Typography color="white" fontSize={20} fontWeight={400} lineHeight={1}>
        Este es un resumen con las principales métricas e indicadores de tu{' '}
        <Box component="span" color="#CAFD72">
          actividad legal 2024
        </Box>
        .
      </Typography>

      <ShareButton onClick={onClick} onDownloadClick={onDownloadClick} />

      <Wrapped2024Footer mx="auto" fontSize={14} />
    </Stack>
  );
};

const directions = ['column', 'column', 'row', 'row'];

const Wrapped2024PageBody = () => {
  const { organization } = useOrganization();

  const { loading } = useWrapped2024();
  const userRankingRef = useRef(null);
  const userMainMetricRef = useRef(null);
  const userMetricsSummaryRef = useRef(null);
  const { isTablet } = useBreakpoints();

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: 200,
        }}
      >
        <CircularProgress color="primary" size={30} />
      </Box>
    );
  }

  if (!organization?.id) {
    return (
      <Stack
        sx={{
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: 200,
        }}
      >
        <Typography fontSize={20} fontWeight={600} color="white">
          Debes seleccionar una organization
        </Typography>

        <StudioSwitcher />
      </Stack>
    );
  }

  return (
    <>
      <Stack
        direction={directions}
        p={[2, 4, 12]}
        gap={[1, 8, 8]}
        justifyContent="center"
        alignItems="center"
      >
        <UserRankingCard ref={userRankingRef} />

        <Box>
          <ShareUserRanking
            onDownloadClick={() =>
              handleDownloadImage(
                userRankingRef.current,
                'resumen legalsurf 2024 - ranking de usuario',
              )
            }
            onClick={() =>
              handleShareImage(
                userRankingRef.current,
                'resumen legalsurf 2024 - ranking de usuario',
                {
                  isTablet,
                },
              )
            }
          />
        </Box>
      </Stack>

      <Stack
        direction={directions}
        p={[2, 4, 12]}
        gap={[1, 8, 8]}
        justifyContent="center"
        alignItems="center"
      >
        <Box sx={{ order: [2, 2, 'initial', 'initial'] }}>
          <ShareMainMetric
            onDownloadClick={() =>
              handleDownloadImage(
                userMainMetricRef.current,
                'resumen legalsurf 2024 - metrica principal',
              )
            }
            onClick={() =>
              handleShareImage(
                userMainMetricRef.current,
                'resumen legalsurf 2024 - metrica principal',
                {
                  isTablet,
                },
              )
            }
          />
        </Box>

        <UserMainMetricCard ref={userMainMetricRef} />
      </Stack>

      <Stack
        direction={directions}
        p={[2, 4, 12]}
        gap={[1, 8, 8]}
        justifyContent="center"
        alignItems="center"
      >
        <UserMetricsSummaryCard ref={userMetricsSummaryRef} />

        <Box>
          <ShareSummaryMetrics
            onDownloadClick={() =>
              handleDownloadImage(
                userMetricsSummaryRef.current,
                'resumen legalsurf 2024 - resumen metrics',
              )
            }
            onClick={() =>
              handleShareImage(
                userMetricsSummaryRef.current,
                'resumen legalsurf 2024 - resumen metrics',
                {
                  isTablet,
                },
              )
            }
          />
        </Box>
      </Stack>
    </>
  );
};

export const Wrapped2024Page = () => {
  const { user } = useUser();
  const { organization } = useOrganization();
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        background: '#171c20',
        backgroundImage: 'url(' + wrappedPageHeroBg + ')',
        width: '100vw',
        minHeight: '100vh',
        backgroundSize: 'contain',
        backgroundPosition: ['top', 'initial', 'initial', 'initial'],
        backgroundRepeat: 'no-repeat',
      }}
    >
      <Fade in>
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{ p: [6, 8, 24], position: 'relative' }}
          gap={2}
          minHeight="100vh"
        >
          <IconButton
            onClick={() =>
              navigate(
                organization?.id
                  ? `/dashboard/${organization.id}/home`
                  : '/dashboard/studios',
              )
            }
            sx={{ position: 'absolute', top: 20, left: 20 }}
            color="background"
          >
            <ArrowBack color="background" />
          </IconButton>

          <Box sx={{ position: 'absolute', top: 20, right: 20 }}>
            <StudioSwitcher />
          </Box>

          <Typography
            textAlign="center"
            color="#CAFD72"
            fontSize={['4rem', '5rem', '6rem', '7rem']}
            fontWeight={600}
            lineHeight={1}
          >
            ¡Gran año {formatName(user?.name) || '...'}!
          </Typography>

          <Typography
            color="white"
            fontSize="2.5rem"
            fontWeight={500}
            textAlign="center"
            lineHeight={1}
          >
            Has tenido un gran 2024 junto a LegalSurf y este es tu resumen de{' '}
            <b>productividad legal del año</b>.
          </Typography>

          <Wrapped2024Footer />
        </Stack>
      </Fade>

      <Box sx={{ backgroundColor: '#171c20' }}>
        <Wrapped2024PageBody />
      </Box>
    </Box>
  );
};
