import React, { useState } from 'react';
import { Box, Button, Stack, Divider, DialogContent } from '@mui/material';
import { Formik } from 'formik';

import useDigitalOceanSpace from 'src/utils/hooks/useDigitalOceanSpace';
import Form, { SubmitButton } from 'src/components/ui-components/Form';
import { EntitiesAutocomplete, FilecasesAutocomplete } from 'src/autocompletes';
import FilesField from 'src/components/ui-components/Form/FilesField';
import { AttachFile, Search } from '@mui/icons-material';
import DocumentsFormValidationSchema from './schema';
import { SearchableDocuments } from './SearchableDocuments';

const defaultFormValues = {
  filecases: [],
  entities: [],
  documents: [],
};

const DocumentsForm = ({
  initialValues,
  enableReinitialize,
  searchable,
  onCancel,
  onSubmit,
  hideEntity,
  hideFilecase,
  disableDocuments = [],
  disableFields = {},
  mutationOptions = {},
  ...rest
}) => {
  const [formType, setFormType] = useState('upload');
  const { handleUpload, handleLinkDocument } = useDigitalOceanSpace({
    ...mutationOptions,
    refetchQueries: [
      'paginatedDocuments',
      'getPaginatedDocuments',
      'documents',
      'DocumentsQuery',
      'DocumentsSearchQuery',
      ...(mutationOptions?.refetchQueries || []),
    ],
  });

  const handleDocumentsSubmit = async (values, formikBag) => {
    try {
      const res = await handleUpload(values);

      if (typeof onSubmit === 'function') {
        onSubmit(res);
      }

      onCancel();
    } finally {
      formikBag.setSubmitting(false);
    }
  };

  const handleSearchSubmit = async (values) => {
    const res = await handleLinkDocument({
      ...values,
      documentId: values?.document?.value,
    });

    if (typeof onSubmit === 'function') {
      onSubmit(res);
    }

    onCancel();
  };

  const isSearchSelected = formType === 'search' && searchable;
  const isUploadSelected = formType === 'upload';

  return (
    <DialogContent>
      <Box sx={{ pt: 1 }}>
        {searchable && (
          <>
            <Stack direction="row" gap={2}>
              <Button
                fullWidth
                color="primary"
                startIcon={<Search />}
                sx={{
                  p: 3,
                }}
                variant={isSearchSelected ? 'contained' : 'outlined'}
                onClick={() => setFormType('search')}
              >
                Buscar documentos
              </Button>

              <Button
                fullWidth
                color="primary"
                startIcon={<AttachFile />}
                sx={{
                  p: 3,
                }}
                variant={isSearchSelected ? 'outlined' : 'contained'}
                onClick={() => setFormType('upload')}
              >
                Adjuntar nuevo
              </Button>
            </Stack>

            <Divider sx={{ my: 2 }} />
          </>
        )}

        {isSearchSelected && (
          <Stack mt={2} pb={0}>
            <Formik
              enableReinitialize={enableReinitialize}
              initialValues={{ ...initialValues, document: null }}
              onSubmit={handleSearchSubmit}
            >
              <Form id="documents-search-form">
                <SearchableDocuments
                  getOptionDisabled={(option) =>
                    disableDocuments.indexOf(option.value) >= 0
                  }
                  name="document"
                />
              </Form>
            </Formik>

            <Box sx={{ textAlign: 'right', mt: 2 }}>
              <Button
                form="documents-search-form"
                type="submit"
                variant="contained"
              >
                Selecionar documento
              </Button>
            </Box>
          </Stack>
        )}

        {isUploadSelected && (
          <Formik
            enableReinitialize={enableReinitialize}
            initialValues={{ ...defaultFormValues, ...initialValues }}
            validateOnBlur={false}
            validateOnChange={false}
            validationSchema={DocumentsFormValidationSchema}
            onSubmit={handleDocumentsSubmit}
            {...rest}
          >
            <Form id="documents-form">
              {!hideFilecase && (
                <FilecasesAutocomplete
                  multiple
                  disabled={disableFields.filecase}
                  helperText="Vincula a un expediente"
                  name="filecases"
                />
              )}

              {!hideEntity && (
                <EntitiesAutocomplete
                  multiple
                  disabled={disableFields.entity}
                  helperText="Vincula a un directorio"
                  name="entities"
                />
              )}

              <FilesField name="documents" sx={{ pt: 0 }} />

              <SubmitButton
                form="documents-form"
                gridProps={{ ml: 'auto' }}
                type="submit"
              >
                Subir documentos
              </SubmitButton>
            </Form>
          </Formik>
        )}
      </Box>
    </DialogContent>
  );
};

export default DocumentsForm;
