import { useState, useCallback } from 'react';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import dayjs from 'dayjs';

import { useSnackbar } from 'src/components/v3/Snackbar';
import { downloadFileByBlobStrategy } from 'src/utils/files';
import { useStudioId } from './useStudioId';

export const UploadFilesMutation = gql`
  mutation uploadFiles(
    $files: [FileUpload!]!
    $studioId: ID!
    $filecases: [ID!]
    $entities: [ID!]
    $sales: [ID!]
    $expenses: [ID!]
  ) {
    uploadFiles(
      files: $files
      studioId: $studioId
      filecases: $filecases
      entities: $entities
      sales: $sales
      expenses: $expenses
    ) {
      id
      size
      kind
      isFolder
      extension
      mimetype
      name
      url
    }
  }
`;

export const UpdateDocumentMutation = gql`
  mutation UpdateDocumentMutation(
    $documentId: ID!
    $name: String
    $filecases: [ID!]
    $entities: [ID!]
  ) {
    updateDocument(
      documentId: $documentId
      name: $name
      filecases: $filecases
      entities: $entities
    ) {
      id
      name
      extension
      size
      url
      kind
      isFolder
      filecases {
        id
        title
      }
      entities {
        id
        type
        displayName
      }
    }
  }
`;

export const CHANGE_DOCUMENT_NAME = gql`
  mutation changeDocumentName($key: ID!, $name: String!) {
    changeDocumentName(key: $key, name: $name) {
      id
      name
      extension
      size
      url
      kind
      isFolder
    }
  }
`;

export const DELETE_DOCUMENT = gql`
  mutation deleteDocument($documentIds: [ID!]!, $studioId: ID!) {
    deleteDocument(documentIds: $documentIds, studioId: $studioId)
  }
`;

export const GENERATE_DOCUMENT_SIGNED_URL = gql`
  query generateDocumentSignedUrl($documentId: ID!) {
    generateDocumentSignedUrl(documentId: $documentId)
  }
`;

const UnlinkFromDocumentMutation = gql`
  mutation UnlinkFromDocument(
    $documentId: ID!
    $filecaseId: ID
    $entityId: ID
    $saleId: ID
    $expenseId: ID
  ) {
    unlinkFromDocument(
      documentId: $documentId
      filecaseId: $filecaseId
      entityId: $entityId
      saleId: $saleId
      expenseId: $expenseId
    ) {
      id
      name
      extension
      size
      url
      kind
      isFolder
    }
  }
`;

const LinkToDocumentMutation = gql`
  mutation LinkToDocumentMutation(
    $documentId: ID!
    $filecaseId: ID
    $entityId: ID
    $saleId: ID
    $expenseId: ID
  ) {
    linkToDocument(
      documentId: $documentId
      filecaseId: $filecaseId
      entityId: $entityId
      saleId: $saleId
      expenseId: $expenseId
    ) {
      id
      name
      extension
      size
      url
      kind
      isFolder
    }
  }
`;

const useDigitalOceanSpace = (mutationOptions) => {
  const [isUploading, setIsUploading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isUnlinking, setIsUnlinking] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isLinking, setIsLinking] = useState(false);
  const [isGeneratingSignedUrl, setIsGeneratingSignedUrl] = useState(false);
  const studioId = useStudioId();

  const [temporarySignedDocument, setTemporarySignedDocument] = useState(null);

  const { openSnackbar } = useSnackbar();

  const [uploadFiles] = useMutation(UploadFilesMutation, {
    ...mutationOptions,
  });

  const [changeDocumentName] = useMutation(CHANGE_DOCUMENT_NAME, {
    ...mutationOptions,
  });

  const [deleteDocument] = useMutation(DELETE_DOCUMENT, {
    ...mutationOptions,
  });

  const [unlinkFromDocument] = useMutation(UnlinkFromDocumentMutation, {
    ...mutationOptions,
  });

  const [linkToDocument] = useMutation(LinkToDocumentMutation, {
    ...mutationOptions,
  });

  const [updateDocument, { loading: isUpdatingDocument }] = useMutation(
    UpdateDocumentMutation,
    {
      ...mutationOptions,
    },
  );

  const [findDocument] = useLazyQuery(GENERATE_DOCUMENT_SIGNED_URL, {
    ...mutationOptions,
  });

  const [generateDocumentSignedUrl] = useLazyQuery(
    GENERATE_DOCUMENT_SIGNED_URL,
    {
      ...mutationOptions,
    },
  );

  const handleUpload = useCallback(
    async ({ documents, filecases, entities, sales, expenses }) => {
      setIsUploading(true);

      const mapOptionsToTypenameById = (options = [], typename) =>
        options?.reduce(
          (acc, { value }) => ({ ...acc, [value]: typename }),
          {},
        );

      const typenamesByIds = {
        ...mapOptionsToTypenameById(filecases, 'Filecase'),
        ...mapOptionsToTypenameById(entities, 'Entity'),
        ...mapOptionsToTypenameById(sales, 'Sale'),
        ...mapOptionsToTypenameById(expenses, 'Expense'),
      };

      try {
        const res = await uploadFiles({
          variables: {
            files: documents.map((file) => ({
              id: file.id,
              name: file.name,
              url: file.url,
              mimeType: file.mimeType || '',
              isFolder: file.isFolder,
              kind: file instanceof File ? 'spaces' : file.kind,
              file: file instanceof File ? file : null,
            })),
            studioId,
            filecases: filecases?.map(({ value }) => value),
            entities: entities?.map(({ value }) => value),
            sales: sales?.map(({ value }) => value),
            expenses: expenses?.map(({ value }) => value),
          },
          update: (cache, { data }) => {
            Object.entries(typenamesByIds).map(([id, typename]) => {
              cache.modify({
                optimistic: true,
                id: cache.identify({
                  id,
                  __typename: typename,
                }),
                fields: {
                  documents(docs, { toReference }) {
                    return [...docs, ...data.uploadFiles.map(toReference)];
                  },
                },
              });
            });
          },
        });

        openSnackbar('Sus documentos se han subido con exito.', {
          severity: 'success',
        });

        return res?.data?.uploadFiles;
      } catch (error) {
        openSnackbar(error.message, { severity: 'error' });

        throw new Error(error);
      } finally {
        setIsUploading(false);
      }
    },
    [openSnackbar, studioId, uploadFiles],
  );

  const handleEditDocumentName = useCallback(
    async ({ key, name }) => {
      try {
        await changeDocumentName({
          variables: {
            key,
            name,
          },
        });

        openSnackbar(`Se ha actualizado el nombre a: ${name}`, {
          severity: 'success',
        });
      } catch (error) {
        openSnackbar(error.message, { severity: 'error' });

        throw new Error(error);
      }
    },
    [openSnackbar, changeDocumentName],
  );

  const handleUpdateDocument = useCallback(
    async ({ documentId, name, filecases, entities }) => {
      try {
        const doc = await updateDocument({
          variables: {
            documentId,
            name,
            filecases,
            entities,
          },
        });

        openSnackbar(
          `Se ha actualizado el documento: ${doc.data?.updateDocument?.name} `,
          {
            severity: 'success',
          },
        );
      } catch (error) {
        openSnackbar(error.message, { severity: 'error' });

        throw new Error(error);
      }
    },
    [openSnackbar, updateDocument],
  );

  const handleDeleteDocument = useCallback(
    async (documentIds) => {
      setIsDeleting(true);
      try {
        await deleteDocument({
          variables: {
            studioId,
            documentIds,
          },
          update: (cache) => {
            documentIds.forEach((documentId) => {
              cache.evict(
                cache.identify({
                  __typename: 'Document',
                  __ref: documentId,
                }),
              );
            });
            cache.gc();
          },
        });

        openSnackbar(`Se ha eliminado el documento.`, {
          severity: 'success',
        });
      } catch (error) {
        openSnackbar(error.message, { severity: 'error' });

        throw new Error(error);
      } finally {
        setIsDeleting(false);
      }
    },
    [openSnackbar, studioId, deleteDocument],
  );

  const handleUnlinkDocument = useCallback(
    async (
      { documentId, filecaseId, saleId, expenseId, entityId },
      options = {},
    ) => {
      setIsUnlinking(true);

      try {
        const res = await unlinkFromDocument({
          variables: {
            documentId,
            filecaseId,
            studioId,
            entityId,
            saleId,
            expenseId,
          },
          update: (cache) => {
            const typesById = {
              [filecaseId]: 'Filecase',
              [entityId]: 'Entity',
              [saleId]: 'Sale',
              [expenseId]: 'Expense',
            };

            return [filecaseId, saleId, expenseId, entityId]
              .filter(Boolean)
              .forEach((id) => {
                cache.modify({
                  optimistic: true,
                  id: cache.identify({
                    id,
                    __typename: typesById[id],
                  }),
                  fields: {
                    documents(docs) {
                      return docs.filter(
                        (doc) => !doc?.__ref?.endsWith?.(documentId),
                      );
                    },
                  },
                });
              });
          },
          ...options,
        });

        openSnackbar('Desvinculaste el documento con exito.', {
          severity: 'success',
        });
        return res?.data;
      } catch (error) {
        openSnackbar(error.message, { severity: 'error' });

        throw new Error(error);
      } finally {
        setIsUnlinking(false);
      }
    },
    [openSnackbar, studioId, unlinkFromDocument],
  );

  const handleDownload = useCallback(
    async (documentId) => {
      setIsDownloading(true);

      try {
        let { data: { generateDocumentSignedUrl: file } = {} } =
          await findDocument({
            variables: {
              documentId,
            },
          });

        const fileUrl = new URL(file.link);
        const expiresEpoch = fileUrl.searchParams.get('Expires');

        if (
          expiresEpoch &&
          dayjs.unix(Number(expiresEpoch)).isBefore(dayjs())
        ) {
          file = (
            await findDocument({
              fetchPolicy: 'network-only',
              variables: {
                documentId,
              },
            })
          ).data?.generateDocumentSignedUrl;
        }

        await downloadFileByBlobStrategy(file.link, {
          name: file.name,
          ext: file.extension,
        });
      } catch (error) {
        openSnackbar(error.message, { severity: 'error' });

        throw new Error(error);
      } finally {
        setIsDownloading(false);
      }
    },
    [openSnackbar, findDocument],
  );

  const handleGenerateSignedUrl = useCallback(
    async (documentId, options) => {
      setIsGeneratingSignedUrl(true);
      try {
        const {
          data: { generateDocumentSignedUrl: signedDocument },
        } = await generateDocumentSignedUrl({
          fetchPolicy: 'network-only',
          variables: {
            documentId,
          },
          ...options,
        });

        setTemporarySignedDocument(signedDocument);
      } catch (error) {
        openSnackbar(error.message, { severity: 'error' });

        throw new Error(error);
      } finally {
        setIsGeneratingSignedUrl(false);
      }
    },
    [openSnackbar, generateDocumentSignedUrl],
  );

  const handleLinkDocument = useCallback(
    async (
      { documentId, filecaseId, entityId, saleId, expenseId, ...rest },
      options = {},
    ) => {
      setIsLinking(true);

      try {
        const res = await linkToDocument({
          variables: {
            documentId,
            filecaseId,
            entityId,
            saleId,
            expenseId,
          },
          update: (cache, { data }) => {
            const typesById = {
              [filecaseId]: 'Filecase',
              [entityId]: 'Entity',
              [saleId]: 'Sale',
              [expenseId]: 'Expense',
            };

            return [filecaseId, saleId, expenseId, entityId]
              .filter(Boolean)
              .forEach((id) => {
                cache.modify({
                  optimistic: true,
                  id: cache.identify({
                    id,
                    __typename: typesById[id],
                  }),
                  fields: {
                    documents(docs, { toReference }) {
                      return [toReference(data.linkToDocument), ...docs];
                    },
                  },
                });
              });
          },
          ...options,
        });

        openSnackbar('Vinculaste el documento con exito.', {
          severity: 'success',
        });

        return res?.data?.linkToDocument;
      } catch (error) {
        openSnackbar(error.message, { severity: 'error' });

        throw new Error(error);
      } finally {
        setIsLinking(false);
      }
    },
    [openSnackbar, linkToDocument],
  );

  return {
    isUploading,
    isDeleting,
    isUnlinking,
    isDownloading,
    isLinking,
    isUpdatingDocument,
    isGeneratingSignedUrl,

    temporarySignedDocument,

    handleDownload,
    handleUpload,
    handleEditDocumentName,
    handleDeleteDocument,

    handleUnlinkDocument,
    handleLinkDocument,

    handleUpdateDocument,
    handleGenerateSignedUrl,
  };
};

export default useDigitalOceanSpace;
