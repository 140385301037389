import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import {
  SELECTION_CHANGE_COMMAND,
  FORMAT_TEXT_COMMAND,
  $getSelection,
  $isRangeSelection,
} from 'lexical';
import { mergeRegister } from '@lexical/utils';
import {
  AttachFile,
  FormatBold,
  FormatItalic,
  FormatStrikethrough,
  FormatUnderlined,
} from '@mui/icons-material';
import { Box, Button, IconButton, Stack, Tooltip } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { DatePicker } from 'src/components/ui-components/Form/DateField/DatePicker';
import PermissionGuard from 'src/components/v2/PermissionGuard';
import { LS_PERMISSIONS_MAP } from '@legalsurf/common';
import { SpecialWordPopover } from 'src/popovers/SpecialWordPopover';
import { LSAdd } from 'src/components/icons';
import { LowPriority } from '../../constants';
import { INSERT_DOCUMENTS_COMMAND } from '../DocumentsPlugin';
import { INSERT_SPECIAL_KEYWORD_COMMAND } from '../SpecialKeywordPlugin';
import { useDialog } from 'src/dialogs/Dialogs';

export const ToolbarPlugin = ({
  filesFormProps = {},
  dateProps,
  disableUpload = false,
  toolbarStartComponent = null,
  toolbarEndComponent = null,
  enableDynamicFields = false,
}) => {
  const [editor] = useLexicalComposerContext();
  const toolbarRef = useRef(null);
  const [specialwordEl, setSpecialwordEl] = React.useState(null);
  const [isBold, setIsBold] = useState(false);
  // const [documentFormOpen, setDocumentFormOpen] = useState(false);
  const dispatchDocumentsForm = useDialog('documentsForm');
  const [isItalic, setIsItalic] = useState(false);
  const [isUnderline, setIsUnderline] = useState(false);
  const [isStrikethrough, setIsStrikethrough] = useState(false);

  const updateToolbar = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      // Update text format
      setIsBold(selection.hasFormat('bold'));
      setIsItalic(selection.hasFormat('italic'));
      setIsUnderline(selection.hasFormat('underline'));
      setIsStrikethrough(selection.hasFormat('strikethrough'));
    }
  }, []);

  useEffect(
    () =>
      mergeRegister(
        editor.registerUpdateListener(({ editorState }) => {
          editorState.read(() => {
            updateToolbar();
          });
        }),
        editor.registerCommand(
          SELECTION_CHANGE_COMMAND,
          () => {
            updateToolbar();
            return false;
          },
          LowPriority,
        ),
      ),
    [editor, updateToolbar],
  );

  if (!editor._editable) {
    return null;
  }

  return (
    <Stack
      className="toolbar"
      direction="row"
      gap={0.5}
      mt={2}
      ref={toolbarRef}
    >
      {toolbarStartComponent}

      {!!dateProps && (
        <Box sx={{ display: 'inline-flex' }}>
          <LocalizationProvider adapterLocale="es" dateAdapter={AdapterDayjs}>
            <DatePicker
              label=""
              size="small"
              {...dateProps}
              inputProps={{ sx: { maxWidth: 90 } }}
            />
          </LocalizationProvider>
        </Box>
      )}

      <IconButton
        aria-label="Format Bold"
        className={`toolbar-item spaced ${isBold ? 'active' : ''}`}
        size="small"
        onClick={() => {
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold');
        }}
      >
        <FormatBold />
      </IconButton>
      <IconButton
        aria-label="Format Italics"
        className={`toolbar-item spaced ${isItalic ? 'active' : ''}`}
        size="small"
        onClick={() => {
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic');
        }}
      >
        <FormatItalic />
      </IconButton>
      <IconButton
        aria-label="Format Underline"
        className={`toolbar-item spaced ${isUnderline ? 'active' : ''}`}
        size="small"
        onClick={() => {
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'underline');
        }}
      >
        <FormatUnderlined />
      </IconButton>
      <IconButton
        aria-label="Format Strikethrough"
        className={`toolbar-item spaced ${isStrikethrough ? 'active' : ''}`}
        size="small"
        onClick={() => {
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'strikethrough');
        }}
      >
        <FormatStrikethrough />
      </IconButton>

      {!disableUpload && (
        <PermissionGuard
          all
          permissions={[
            LS_PERMISSIONS_MAP.DOCUMENTS.ACCESS,
            LS_PERMISSIONS_MAP.DOCUMENTS.CREATE,
          ]}
        >
          <IconButton
            aria-label="Adjuntar document"
            size="small"
            onClick={() =>
              dispatchDocumentsForm('ToolbarPlugin', {
                hideEntity: true,
                hideFilecase: true,
                searchable: true,
                onSubmit: (files) => {
                  if (files?.length) {
                    files.forEach((file) => {
                      editor.dispatchCommand(INSERT_DOCUMENTS_COMMAND, file);
                    });
                  } else if (files) {
                    editor.dispatchCommand(INSERT_DOCUMENTS_COMMAND, files);
                  }
                },
                ...filesFormProps,
              })
            }
          >
            <AttachFile />
          </IconButton>
        </PermissionGuard>
      )}

      {enableDynamicFields && (
        <>
          <Tooltip title="Utiliza campos dinámicos que serán rellenados en el momento de utilizar esta plantilla">
            <Button
              size="small"
              startIcon={<LSAdd />}
              variant="contained"
              onClick={(event) => setSpecialwordEl(event.currentTarget)}
            >
              Campos dinámicos
            </Button>
          </Tooltip>

          <SpecialWordPopover
            anchorEl={specialwordEl}
            open={!!specialwordEl}
            onClick={(payload) => {
              editor.dispatchCommand(INSERT_SPECIAL_KEYWORD_COMMAND, payload);
              setSpecialwordEl(null);
            }}
            onClose={() => setSpecialwordEl(null)}
          />
        </>
      )}

      {toolbarEndComponent}
    </Stack>
  );
};
