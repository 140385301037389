import { gql } from '@apollo/client';

export const CalendarEventsQuery = gql`
  query getCalendarPageData(
    $studio: ID!
    $text: String
    $calendars: [ID!]
    $taskStates: [TaskState!]
    $assigned: [ID!]
    $entity: ID
    $type: EventType
    $filecases: [ID!]
    $start: DateTime
    $end: DateTime
  ) {
    events(
      studio: $studio
      filters: {
        type: $type
        text: $text
        calendars: $calendars
        assigned: $assigned
        entity: $entity
        state: $taskStates
        filecases: $filecases
        start: $start
        end: $end
      }
    ) {
      id
      calendarId
      title
      type
      description
      start
      end
      state
      allDay
      timezone
      googleCalendarData
      calendar {
        id
        name
        googleCalendarId
        timezone
        color
      }
      todos {
        id
        title
        done
        eventId
        calendarId
        studioId
      }
      assigned {
        id
        name
        picture
      }
    }
  }
`;

export const CalendarFiltersQuery = gql`
  query getCalendarFilters($studio: ID!) {
    members(studio: $studio) {
      id
      name
      email
    }

    entities(studio: $studio) {
      id
      displayName
      businessname
      firstname
      lastname
    }

    filecases(studio: $studio) {
      id
      title
    }
  }
`;

export const CalendarsQuery = gql`
  query getCalendars($studio: ID!) {
    calendars(studio: $studio) {
      id
      color
      name
      timezone
      googleCalendarId
      googleCalendarNeedsResync
      canBeSeenBy {
        id
        name
        email
        picture
      }
      createdBy {
        id
        timezone
        email
        name
      }
    }
  }
`;

export const UpdateCalendarEventQuery = gql`
  mutation updateEvent(
    $id: ID!
    $type: EventType
    $title: String
    $description: String
    $allDay: Boolean
    $start: DateTime
    $end: DateTime
    $calendar: ID!
    $filecase: ID
    $entities: [ID!]
    $assigned: ID
    $taskState: TaskState
    $canBeSeenBy: [ID!]
  ) {
    updateEvent(
      id: $id
      type: $type
      title: $title
      description: $description
      allDay: $allDay
      start: $start
      end: $end
      calendar: $calendar
      filecase: $filecase
      entities: $entities
      assigned: $assigned
      taskState: $taskState
      canBeSeenBy: $canBeSeenBy
    ) {
      id
      calendarId
      title
      start
      type
      end
      allDay
      description
      state
      googleCalendarData
      calendar {
        id
        name
        color
      }
      assigned {
        id
        name
        picture
      }
      filecase {
        id
        title
        externalId
      }
      entities {
        id
        displayName
        type
      }
    }
  }
`;

export const SingleCalendarEventQuery = gql`
  query SingleCalendarEventQuery($id: ID!, $calendarId: ID!) {
    event(id: $id, calendarId: $calendarId) {
      id
      calendarId
      title
      start
      type
      end
      timezone
      state
      allDay
      description
      googleCalendarData
      calendar {
        id
        timezone
        name
        color
      }
      todos {
        id
        title
        done
        eventId
        calendarId
        studioId
      }
      comments {
        id
        text
        createdAt
        eventId
        calendarId
        createdBy {
          id
          name
          timezone
          picture
        }
      }
      filecase {
        id
        title
        externalId
        description
      }
      assigned {
        id
        name
        picture
      }
      createdBy {
        id
        timezone
        name
        picture
      }
      entities {
        id
        type
        displayName
        businessname
        firstname
        lastname
        phone
      }
    }
  }
`;

export const DeleteCalendarEventQuery = gql`
  mutation deleteEvent($id: ID!, $calendarId: ID!) {
    deleteEvent(id: $id, calendarId: $calendarId)
  }
`;

export const UpdateCalendarEventStatus = gql`
  mutation UpdateCalendarEventStatus(
    $id: ID!
    $calendarId: ID!
    $state: TaskState!
  ) {
    updateEventStatus(id: $id, calendarId: $calendarId, state: $state) {
      type
      id
      calendarId
      studioId
      state
      updatedAt
    }
  }
`;

export const UpdateCalendarEventAssignees = gql`
  mutation UpdateCalendarEventAssignees(
    $id: ID!
    $calendarId: ID!
    $assigned: [ID!]
  ) {
    updateEvent(id: $id, calendar: $calendarId, assigned: $assigned) {
      id
      calendarId
      studioId
      assigned {
        id
        name
        picture
      }
    }
  }
`;

export const CreateEventComment = gql`
  mutation CreateEventComment(
    $text: String!
    $calendarId: ID!
    $eventId: ID!
    $studioId: ID!
  ) {
    createEventComment(
      text: $text
      calendarId: $calendarId
      eventId: $eventId
      studioId: $studioId
    ) {
      id
      eventId
      calendarId
      text
    }
  }
`;

export const EventComments = gql`
  query EventComments($calendarId: ID!, $eventId: ID!, $studioId: ID!) {
    eventComments(
      calendarId: $calendarId
      eventId: $eventId
      studioId: $studioId
    ) {
      id
      text
      createdAt
      eventId
      calendarId
      createdBy {
        id
        name
        timezone
        picture
      }
    }
  }
`;

export const UpdateEventComment = gql`
  mutation UpdateEventCommentMutation(
    $commentId: ID!
    $text: String!
    $calendarId: ID!
    $eventId: ID!
    $studioId: ID!
  ) {
    updateEventComment(
      commentId: $commentId
      text: $text
      calendarId: $calendarId
      eventId: $eventId
      studioId: $studioId
    ) {
      id
      eventId
      calendarId
      text
      updatedAt
    }
  }
`;

export const CreateTodo = gql`
  mutation CreateTodo(
    $title: String!
    $eventId: ID!
    $studioId: ID!
    $calendarId: ID!
  ) {
    createEventTodo(
      title: $title
      eventId: $eventId
      studioId: $studioId
      calendarId: $calendarId
    ) {
      id
      title
      done
      studioId
      calendarId
      eventId
    }
  }
`;

export const DeleteTodo = gql`
  mutation DeleteTodo($id: ID!, $studioId: ID!, $calendarId: ID!) {
    deleteEventTodo(id: $id, studioId: $studioId, calendarId: $calendarId) {
      id
      title
      done
      studioId
      calendarId
      eventId
    }
  }
`;

export const UpdateTodo = gql`
  mutation UpdateTodo(
    $id: ID!
    $title: String!
    $studioId: ID!
    $calendarId: ID!
    $done: Boolean!
  ) {
    updateEventTodo(
      id: $id
      title: $title
      studioId: $studioId
      calendarId: $calendarId
      done: $done
    ) {
      id
      title
      done
      studioId
      calendarId
      eventId
    }
  }
`;
