import React from 'react';
import { Box } from '@mui/material';
import { LS_PERMISSIONS_MAP } from '@legalsurf/common';
import { usePermissions } from 'src/utils/hooks/usePermissions';
import { File } from 'src/components/v2/FilesDropzone';
import { useDialog } from 'src/dialogs/Dialogs';
import useDigitalOceanSpace from 'src/utils/hooks/useDigitalOceanSpace';
import { CreationInfoBanner } from 'src/components/v3/CreationInfoBanner';

export const DocumentsSection = ({ sale }) => {
  const dispatchWarningPromptDialog = useDialog('warningPrompt');
  const dispatchDocumentsFormDialog = useDialog('documentsForm');

  const { handleUnlinkDocument } = useDigitalOceanSpace();

  const { hasPermissions } = usePermissions([
    LS_PERMISSIONS_MAP.DOCUMENTS.ACCESS,
  ]);

  const { hasPermissions: hasCreatePermissions } = usePermissions([
    LS_PERMISSIONS_MAP.DOCUMENTS.CREATE,
  ]);

  const { hasPermissions: hasDeletePermissions } = usePermissions([
    LS_PERMISSIONS_MAP.DOCUMENTS.UPDATE,
    LS_PERMISSIONS_MAP.SALES.UPDATE,
  ]);

  const handleRemove = (file) =>
    dispatchWarningPromptDialog(`SingleDirectoryDocuments-${file.id}`, {
      onAccept: () =>
        handleUnlinkDocument({
          documentId: file.id,
          saleId: sale.id,
        }),
      title: 'Desvincular documento de honorario',
      content: `¿Estas seguro que deseas desvincular el documento ${file.name} de tu honorario?`,
    });

  if (!hasPermissions) {
    return null;
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      {hasCreatePermissions && (
        <CreationInfoBanner
          buttonStr="Subir documentos"
          description="Sube archivos y adjuntalo a tu honorario"
          title="Adjuntar documentos"
          onClick={() =>
            dispatchDocumentsFormDialog('SingleSaleDocumentsForm', {
              searchable: true,
              hideEntity: true,
              hideFilecase: true,
              disableFields: {
                entity: true,
                filecase: true,
              },
              file: {
                saleId: sale.id,
                sales: [
                  {
                    value: sale.id,
                    label: '',
                  },
                ],
              },
            })
          }
        />
      )}

      {sale?.documents.map((document) => (
        <File
          document={document}
          downloable={document.kind === 'spaces'}
          key={document.id}
          previewable={document.kind === 'spaces'}
          {...(hasDeletePermissions && {
            onRemove: handleRemove,
          })}
        />
      ))}
    </Box>
  );
};
