import React, { forwardRef } from 'react';
import { Grid } from '@mui/material';
import { useField, useFormikContext } from 'formik';
import UserInput from 'src/components/v3/UserInput';

// eslint-disable-next-line react/display-name
const CommentField = forwardRef(
  (
    {
      label,
      gridProps = { xs: 12 },
      onChange,
      error,
      helperText,
      name,
      disabled,
      ...rest
    },
    ref,
  ) => {
    const [field, meta, helpers] = useField(name);
    const { isSubmitting, handleSubmit, errors } = useFormikContext();

    return (
      <Grid item {...gridProps}>
        <UserInput
          disabled={isSubmitting || disabled}
          error={error || Boolean(meta.touched && meta.error)}
          helperText={helperText || (meta.touched && meta.error)}
          errors={errors}
          initialValue={meta.initialValue}
          label={label}
          ref={ref}
          variant="outlined"
          onSubmit={handleSubmit}
          {...field}
          onBlur={() => helpers.setTouched(true)}
          onChange={(value) => {
            helpers.setValue(value);

            if (onChange) {
              onChange(value);
            }
          }}
          {...rest}
        />
      </Grid>
    );
  },
);

export default CommentField;
