import { Box, Button, Dialog, Fade, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import homeWrappedBTN from './home-wrapped-btn.png';
import homeWrappedModalHero from './home-wrapped-modal-hero.png';
import { ArrowRight } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import { useStudioId } from 'src/utils/hooks/useStudioId';

// default open model and save to local storage
const defaultOpen = !localStorage.getItem('homeWrapped2024CardOpened');

const StudioWrapped2024AvailabilityQuery = gql`
  query StudioWrapped2024AvailabilityQuery($studioId: ID!) {
    studio(id: $studioId) {
      id
      wrapped2024
    }
  }
`;

const useDoesStudioQualifyWrapped2024 = () => {
  const studioId = useStudioId();
  const { data: { studio } = {}, loading } = useQuery(
    StudioWrapped2024AvailabilityQuery,
    {
      fetchPolicy: 'cache-first',
      variables: {
        studioId,
      },
    },
  );

  return { studio, loading };
};

export const HomeWrappedCard = () => {
  const [open, setOpen] = useState(defaultOpen);
  const { studio, loading } = useDoesStudioQualifyWrapped2024();
  const navigate = useNavigate();

  const handleClose = () => {
    localStorage.setItem('homeWrapped2024CardOpened', true);
    setOpen(false);
  };

  const doesUserHaveWrapped2024 = studio?.wrapped2024?.metrics?.filecases >= 10;

  if (loading || !doesUserHaveWrapped2024) {
    return null;
  }

  return (
    <>
      {doesUserHaveWrapped2024 && (
        <Fade in>
          <Box
            sx={{
              cursor: 'pointer',
              '&:hover': {
                opacity: 0.9,
                transition: 'all 0.2s ease-in-out',
                transform: 'scale(1.02)',
              },
              '&:active': {
                opacity: 0.8,
              },
            }}
            onClick={() => setOpen(true)}
          >
            <Box
              sx={{ maxWidth: '100%', borderRadius: 1, overflow: 'hidden' }}
              component="img"
              src={homeWrappedBTN}
              alt="resumen 2024"
            />
          </Box>
        </Fade>
      )}

      <Dialog open={open && !loading} onClose={handleClose}>
        <Box sx={{ bgcolor: '#181c20' }}>
          <Box
            component="img"
            src={homeWrappedModalHero}
            alt="modal hero"
            sx={{ objectFit: 'contain', maxWidth: '100%' }}
          />
        </Box>

        <Stack>
          <Stack sx={{ p: 4 }} gap={2}>
            <Typography variant="h3">!Tu resumen 2024 está aquí! 🏄</Typography>

            <Typography variant="body1">
              Has tenido un gran año en LegalSurf y queremos compartir contigo
              un breve resumen de toda tu actividad.
            </Typography>

            <Box>
              <Button
                variant="outlined"
                onClick={() => navigate('/resumen-2024')}
                endIcon={<ArrowRight />}
              >
                Ver mi resumen
              </Button>
            </Box>
          </Stack>
        </Stack>
      </Dialog>
    </>
  );
};
