import React from 'react';
import { useDropzone } from 'react-dropzone';
import { Grid, Box } from '@mui/material';

import { getFileExtension } from 'src/utils/formatters';
import { UploadButton } from './UploadButton';
import File from './File';
import { GoogleDriveButton } from './GoogleDriveButton';
import { OneDriveButton } from './OneDriveButton';
import { DropboxButton } from './DropboxButton';

const FilesDropzone = ({
  className,
  disabled,
  loading,
  hideButton = false,
  subFolder,
  onChange,
  onRemove,
  value = [],
  multiple = true,
  noGoogleDrive = false,
  noOneDrive = false,
  noDropbox = false,
  ...rest
}) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: onChange,
    multiple: true,
  });

  return (
    <Box {...rest}>
      {!hideButton && (
        <UploadButton
          disabled={disabled}
          getRootProps={getRootProps}
          isDragActive={isDragActive}
          multiple={multiple}
        >
          <input {...getInputProps()} />
        </UploadButton>
      )}

      <Box sx={{ display: 'flex', justifyContent: 'center', mb: 4, gap: 2 }}>
        {!noGoogleDrive && (
          <GoogleDriveButton disabled={disabled} onSelect={onChange} />
        )}

        {!noOneDrive && <OneDriveButton onSelect={onChange} />}

        {!noDropbox && <DropboxButton onSelect={onChange} />}
      </Box>

      {(multiple ? !!value?.length : !!value) && (
        <Grid container spacing={2} sx={{ mt: 1 }}>
          {(multiple ? value : [value]).map((file) => (
            <File
              document={{
                ...file,
                extension: file.extension || getFileExtension(file.name),
                name: file.name,
                size: file.size,
                key: file.path,
              }}
              key={file.path ?? file.id}
              loading={loading}
              path={file.path}
              onRemove={({ name }) =>
                onRemove(value.filter((oldValue) => oldValue.name !== name))
              }
            />
          ))}
        </Grid>
      )}
    </Box>
  );
};

export { File };
export default FilesDropzone;
