import React, { useCallback, useMemo } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import {
  Box,
  Chip,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { DragDropContext, Droppable } from '@hello-pangea/dnd';
import { DEFAULT_PAYMENT_METHODS, LS_PERMISSIONS_MAP } from '@legalsurf/common';
import { useDraggableSections } from '@legalsurf/hooks';

import DetailSection from 'src/components/v2/FilecaseDetail/DetailSection';
import { LSDocument, LSEdit, LSMessages, LSTrash } from 'src/components/icons';

import PermissionGuard from 'src/components/v2/PermissionGuard';
import { useStudioId } from 'src/utils/hooks/useStudioId';
import DetailsColumnSkeleton from './DetailsColumnSkeleton';
import { Comments } from './Comments';
import SingleFilecaseErrorBoundary from '../FilecaseDetail/SingleFilecaseErrorBoundary';
import { ErrorBoundary } from '@sentry/react';
import { timelineContentClasses } from '@mui/lab';
import {
  DELETE_CHARGE,
  GET_SALE,
} from 'src/pages/Dashboard/Sales/SalesQueries';
import { SingleSaleHeader } from './SingleSaleHeader';
import { Receipt } from '@mui/icons-material';
import { toDateFormat } from 'src/utils/dates';
import { CreationInfoBanner } from 'src/components/v3/CreationInfoBanner';
import { useDialog } from 'src/dialogs/Dialogs';
import { formatMoney } from 'src/utils/formatters';
import { DocumentsSection } from './DocumentsSection';

const ChargesSection = ({ sale }) => {
  const studioId = useStudioId();
  const dispatchChargeFormDialog = useDialog('chargeForm');
  const dispatchWarningPromptDialog = useDialog('warningPrompt');

  const [deleteCharge, { loading: isDeletingCharge }] = useMutation(
    DELETE_CHARGE,
    {
      update: (cache, result) => {
        cache.evict({
          id: cache.identify(result.data.deleteCharge),
        });
      },
    },
  );

  return (
    <Stack gap={2}>
      {/* {hasCreatePermissions && ( */}
      <CreationInfoBanner
        buttonStr="Cargar cobro"
        description="Usa los comentarios para subir algun archivo o hacer alguna anotación"
        title="Cargar cobro a tus honorarios"
        onClick={() =>
          dispatchChargeFormDialog('ChargeDetailsForm Dialog', {
            sale,
          })
        }
      />
      {/* )} */}

      <Stack gap={1}>
        {sale.charges?.map((charge) => (
          <Stack direction="row" alignItems="center" gap={2} key={charge.id}>
            <Typography fontWeight={400}>
              {toDateFormat(charge.date)}
            </Typography>

            <Chip color="primary" label="Cobro" value="Cobro" />

            <Typography variant="h6">
              {sale?.currency?.name} {formatMoney(charge.amount)}
            </Typography>

            {charge.concept && (
              <Tooltip title={charge.concept}>
                <Typography
                  noWrap
                  sx={{ width: 150, flex: 1, fontWeight: 400 }}
                  variant="h6"
                >
                  {charge.concept}
                </Typography>
              </Tooltip>
            )}

            <Stack direction="row" gap={0.5} ml="auto">
              <Chip
                color="primary"
                label={
                  DEFAULT_PAYMENT_METHODS.find(
                    (method) => method.value === charge.method,
                  ).label
                }
                sx={{ ml: 'auto' }}
                value={
                  DEFAULT_PAYMENT_METHODS.find(
                    (method) => method.value === charge.method,
                  ).label
                }
                variant="light"
              />

              <PermissionGuard permissions={LS_PERMISSIONS_MAP.SALES.DELETE}>
                <Tooltip title="Eliminar cobro">
                  <IconButton
                    size="small"
                    disabled={isDeletingCharge}
                    onClick={() =>
                      dispatchWarningPromptDialog(charge.id, {
                        onAccept: () =>
                          deleteCharge({
                            variables: {
                              studioId,
                              id: charge.id,
                            },
                          }),
                      })
                    }
                  >
                    <LSTrash fontSize="small" />
                  </IconButton>
                </Tooltip>
              </PermissionGuard>

              <PermissionGuard permissions={LS_PERMISSIONS_MAP.SALES.UPDATE}>
                <Tooltip title="Editar ese cobro">
                  <IconButton
                    size="small"
                    disabled={isDeletingCharge}
                    onClick={() =>
                      dispatchChargeFormDialog(charge.id, {
                        sale,
                        charge,
                      })
                    }
                  >
                    <LSEdit fontSize="small" />
                  </IconButton>
                </Tooltip>
              </PermissionGuard>

              <Tooltip title="Descargar Comprobante">
                <IconButton
                  size="small"
                  disabled={isDeletingCharge}
                  onClick={() =>
                    window.open(
                      `/dashboard/${studioId}/charge/${charge.id}/voucher`,
                      '_blank',
                    )
                  }
                >
                  <LSDocument />
                </IconButton>
              </Tooltip>
            </Stack>
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
};
const rawSections = ['charges', 'documents', 'comments'];

const getSections = (sectionId) => {
  switch (sectionId) {
    case 'comments':
      return Comments;
    case 'documents':
      return DocumentsSection;
    case 'charges':
      return ChargesSection;
    default:
      return null;
  }
};

const useSections = (sale) => {
  const getTitle = useCallback(
    ({ type, title }) => {
      if (!sale) {
        return title;
      }

      const casts = {
        charges: () => sale.charges.length,
        comments: () => sale.comments.length,
        documents: () => sale.documents.length,
      };

      const count = casts[type]();

      return count ? `${title} (${count})` : title;
    },
    [sale],
  );

  const sectionData = useMemo(
    () => ({
      charges: {
        icon: <Receipt color="primary" />,
        title: getTitle({ type: 'charges', title: 'Cobros' }),
        permissions: [LS_PERMISSIONS_MAP.FILECASES.ACCESS],
      },
      documents: {
        icon: <LSDocument color="primary" />,
        title: getTitle({ type: 'documents', title: 'Documentos vinculados' }),
        permissions: [LS_PERMISSIONS_MAP.DOCUMENTS.ACCESS],
      },
      comments: {
        icon: <LSMessages color="primary" />,
        title: getTitle({ type: 'comments', title: 'Comentarios' }),
      },
    }),
    [getTitle],
  );

  return sectionData;
};

export const SaleDetail = ({ saleId, onClose }) => {
  const { data: { sale } = {}, loading } = useQuery(GET_SALE, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      id: saleId,
    },
  });

  const sectionData = useSections(sale);
  const [sections, handleDragEnd] = useDraggableSections(
    rawSections,
    'v3-sale-detail',
  );

  if (loading) {
    return <DetailsColumnSkeleton />;
  }

  return (
    <Box
      sx={{
        p: 4,
        // Overrides any inputs background, if this patter repeats, find a better way.
        overflow: 'hidden',
        fieldset: { backgroundColor: '#FFF !important' },

        [`& .${timelineContentClasses.root}`]: {
          maxWidth: (theme) => `calc(100% - ${theme.spacing(3)})`,
        },

        '& :before': {
          display: 'none',
        },
      }}
    >
      {!!saleId && (
        <ErrorBoundary fallback={SingleFilecaseErrorBoundary}>
          <Stack gap={2}>
            <SingleSaleHeader onClose={onClose} sale={sale} />

            <DragDropContext onDragEnd={handleDragEnd}>
              <Stack>
                {sections.map((sectionId, index) => {
                  const Section = getSections(sectionId);
                  const { title, icon, permissions } = sectionData[sectionId];

                  const content = (
                    <Droppable
                      direction="vertical"
                      droppableId={sectionId}
                      key={sectionId}
                    >
                      {(dropProvided, snapshot) => (
                        <Box
                          {...dropProvided.droppableProps}
                          direction="column"
                          ref={dropProvided.innerRef}
                          sx={{
                            ...(snapshot.isDraggingOver && {
                              backgroundColor: 'primary.100',
                            }),
                          }}
                        >
                          <DetailSection
                            draggableId={sectionId}
                            icon={icon}
                            index={index}
                            title={title}
                          >
                            <Section sale={sale} type={sectionId} />
                          </DetailSection>

                          {dropProvided.placeholder}
                        </Box>
                      )}
                    </Droppable>
                  );

                  if (!permissions) {
                    return content;
                  }

                  return (
                    <PermissionGuard key={sectionId} permissions={permissions}>
                      {content}
                    </PermissionGuard>
                  );
                })}
              </Stack>
            </DragDropContext>
          </Stack>
        </ErrorBoundary>
      )}
    </Box>
  );
};
