import { gql } from '@apollo/client';

export const DeleteCommentMutation = gql`
  mutation DeleteCommentMutation($commentId: ID!, $studioId: ID!) {
    deleteComment(commentId: $commentId, studioId: $studioId) {
      id
      content
      updatedAt
      createdAt
      createdBy {
        id
        name
        picture
      }
    }
  }
`;

export const UpdateCommentMutation = gql`
  mutation UpdateCommentMutation(
    $commentId: ID!
    $content: String!
    $studioId: ID!
  ) {
    updateComment(
      commentId: $commentId
      content: $content
      studioId: $studioId
    ) {
      id
      content
      entityId
      saleId
      expenseId
      updatedAt
      createdAt
      createdBy {
        id
        name
        picture
      }
    }
  }
`;

export const CreateComment = gql`
  mutation CreateComment(
    $entityId: ID
    $saleId: ID
    $expenseId: ID
    $studioId: ID!
    $content: String!
  ) {
    createComment(
      entityId: $entityId
      saleId: $saleId
      expenseId: $expenseId

      content: $content
      studioId: $studioId
    ) {
      id
      content
      entityId
      saleId
      expenseId
      updatedAt
      createdAt

      createdBy {
        id
        name
        picture
      }
    }
  }
`;
