import React, { useState } from 'react';
import {
  Avatar,
  Box,
  Typography,
  Stack,
  Card,
  CardContent,
  Tooltip,
  AvatarGroup,
  IconButton,
  darken,
} from '@mui/material';
import { LS_PERMISSIONS_MAP } from '@legalsurf/common';
import PermissionGuard from 'src/components/v2/PermissionGuard';
import { LSDangerSign, LSFilecase, LSPin } from 'src/components/icons';
import { useStudioId } from 'src/utils/hooks/useStudioId';

import { useStudio } from 'src/utils/hooks/useStudio';
import { useStudioMembers } from '@legalsurf/hooks';
import { useUser } from 'src/utils/hooks/useUser';
import { useNavigate } from 'react-router-dom';
import { ManageMembersModal } from 'src/popovers/ManageMembersPopover';
import { Add, ChevronRight } from '@mui/icons-material';
import { gql, useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import DisabledBackdrop from 'src/components/v2/DisabledBackdrop';
import { HomeWrappedCard } from 'src/components/Wrapped2024/HomeWrappedCard';

export const HomeScreenQuery = gql`
  query HomeScreenQuery(
    $assigned: [ID!]!
    $start: DateTime!
    $end: DateTime!
    $studioId: ID!
  ) {
    paginatedFilecases(studio: $studioId, assigned: $assigned) {
      count
    }

    myTasks: events(
      studio: $studioId
      filters: {
        assigned: $assigned
        type: task
        state: [pending, wip, done, reviewing]
      }
    ) {
      id
      calendarId
      type
      studioId
    }

    todayAlerts: events(
      studio: $studioId
      filters: { start: $start, end: $end, type: alert }
    ) {
      id
      calendarId
      type
      studioId
    }
  }
`;

const useHomeUserSummaryQuery = () => {
  const studioId = useStudioId();
  const { user } = useUser();

  return useQuery(HomeScreenQuery, {
    notifyOnNetworkStatusChange: true,
    variables: {
      studioId,
      assigned: [user.id],
      start: dayjs().startOf('date').toISOString(),
      end: dayjs().endOf('date').toISOString(),
    },
    skip: !user.id,
    fetchPolicy: 'cache-first',
  });
};

export const PreviewSectionContainer = ({
  title,
  icon,
  count,
  onPress,
  children = null,
  sx = {},
}) => (
  <Box
    backgroundColor="white"
    borderRadius={1}
    sx={{
      borderColor: 'divider',
      borderWidth: 0.5,
      borderStyle: 'solid',
      ...sx,
    }}
    p={2}
    flex={1}
  >
    <Stack>
      <Stack direction="row" alignItems="center" gap={[1, 1, 0.5, 1]}>
        {icon}

        <Typography fontWeight="700" fontSize={18}>
          {count}
        </Typography>

        {onPress && (
          <IconButton
            onClick={onPress}
            sx={{ ml: 'auto' }}
            color="primary"
            size="small"
          >
            <ChevronRight color="primary" />
          </IconButton>
        )}
      </Stack>

      <Typography fontSize={12} fontWeight="500">
        {title}
      </Typography>
    </Stack>

    {children}
  </Box>
);

export const UserStudioCard = () => {
  const { user } = useUser();
  const studioId = useStudioId();
  const { data: userSummary, loading } = useHomeUserSummaryQuery();
  const navigate = useNavigate();
  const { studio } = useStudio({
    fetchPolicy: 'cache-first',
  });
  const { data: members } = useStudioMembers({
    variables: {
      studio: studioId,
    },
  });

  const [anchorManageMembersEl, setAnchorManageMembersEl] = useState(null);

  const handleOpenInvitationsPopover = (event) => {
    setAnchorManageMembersEl(event.currentTarget);
  };

  const handleOpenManageMembersPopover = (event) => {
    setAnchorManageMembersEl(event.currentTarget);
  };

  const handleCloseManageMembersPopover = () => {
    setAnchorManageMembersEl(null);
  };

  return (
    <DisabledBackdrop withIndicator in={loading}>
      <Card
        sx={{
          bgcolor: 'primary.900',
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
        }}
      >
        <CardContent>
          <Stack gap={3} p={1} pb={0}>
            <Stack direction="row" gap={1} alignItems="center">
              <Avatar
                src={studio.picture}
                alt={studio.name}
                sx={{ width: 58, height: 58 }}
              />

              <Typography color="white" fontSize={17}>
                {studio.name}
              </Typography>
            </Stack>

            <Stack gap={1}>
              <Stack direction="row" gap={2} alignItems="center">
                {!!members.length && (
                  <AvatarGroup
                    max={5}
                    total={members.length}
                    slotProps={{
                      additionalAvatar: {
                        sx: {
                          bgcolor: 'primary.900',
                          borderWidth: '0 !important',
                          pl: 1,
                        },
                      },
                    }}
                    sx={{
                      '& > div': {
                        width: '38px !important',
                        height: '38px !important',
                        marginRight: '-12px !important',
                      },
                    }}
                  >
                    {members.map((member) => (
                      <Tooltip key={member.id} title={member.name}>
                        <Avatar
                          alt={member.name}
                          src={member.picture}
                          sx={{
                            borderColor: 'transparent !important',
                            width: 50,
                            height: 50,
                          }}
                        >
                          <Typography fontSize={12} variant="body1">
                            {member.name?.[0]}
                          </Typography>
                        </Avatar>
                      </Tooltip>
                    ))}
                  </AvatarGroup>
                )}

                {['admin', 'owner'].includes(user?.role) && (
                  <Box>
                    <IconButton
                      color="primary"
                      size="small"
                      sx={{
                        bgcolor: 'background.input',
                        ':hover': {
                          bgcolor: (theme) =>
                            darken(theme.palette.background.input, 0.1),
                        },
                      }}
                      onClick={handleOpenInvitationsPopover}
                    >
                      <Add fontSize="small" />
                    </IconButton>
                  </Box>
                )}
              </Stack>

              {['admin', 'owner'].includes(user?.role) && (
                <>
                  <ManageMembersModal
                    open={Boolean(anchorManageMembersEl)}
                    onClose={handleCloseManageMembersPopover}
                    onSubmit={handleCloseManageMembersPopover}
                  />

                  <Typography
                    color="white"
                    fontWeight="500"
                    sx={{ cursor: 'pointer' }}
                    onClick={handleOpenManageMembersPopover}
                  >
                    Administrar equipo
                  </Typography>
                </>
              )}
            </Stack>
          </Stack>
        </CardContent>
      </Card>

      <Card sx={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}>
        <CardContent>
          <Stack p={1} gap={2}>
            <Stack direction="row" gap={2} alignItems="center">
              <Avatar
                alt="Perfil de usuario"
                src={user?.picture}
                sx={{ width: 48, height: 48 }}
              />

              <Stack>
                <Typography variant="h6" fontWeight="600">
                  {user?.name}
                </Typography>

                <Typography variant="body2" fontWeight="500">
                  Aquí un resumen de tu Actividad
                </Typography>
              </Stack>
            </Stack>

            <HomeWrappedCard />

            <Stack gap={1}>
              <Stack direction="row" gap={1}>
                <PermissionGuard
                  permissions={[LS_PERMISSIONS_MAP.CALENDAR.ACCESS]}
                >
                  <PreviewSectionContainer
                    onPress={() => navigate(`/dashboard/${studioId}/calendar`)}
                    count={userSummary?.todayAlerts?.length || 0}
                    title="Alertas hoy"
                    icon={<LSDangerSign color="primary" />}
                  />
                </PermissionGuard>

                <PermissionGuard
                  permissions={[LS_PERMISSIONS_MAP.CALENDAR.ACCESS]}
                >
                  <PreviewSectionContainer
                    count={userSummary?.myTasks?.length || 0}
                    onPress={() =>
                      navigate(
                        `/dashboard/${studioId}/task?onlyMe=1&viewType=board`,
                      )
                    }
                    title="Mis tareas"
                    icon={<LSPin color="primary" />}
                  />
                </PermissionGuard>
              </Stack>

              <PreviewSectionContainer
                count={userSummary?.paginatedFilecases?.count || 0}
                onPress={() =>
                  navigate(
                    `/dashboard/${studioId}/filecases?assigned=${user.id}`,
                  )
                }
                title="Expedientes Asignados"
                icon={<LSFilecase color="primary" />}
              />
            </Stack>
          </Stack>
        </CardContent>
      </Card>
    </DisabledBackdrop>
  );
};
