import React, { forwardRef } from 'react';
import { IconButton, Stack, Typography } from '@mui/material';
import { Send as SendIcon } from '@mui/icons-material';

import LoggedUserAvatar from '../LoggedUserAvatar';
import { RichEditor } from '../RichEditor';

// eslint-disable-next-line react/display-name
const UserInput = forwardRef(
  (
    {
      placeholder = 'Escribe un comentario...',
      onSubmit,
      value,
      initialValue,
      disabled,
      sx = {},
      editorContentSx = {},
      toolbarEndComponent,
      toolbarStartComponent,
      name,
      errors,
      ...props
    },
    ref,
  ) => {
    const richEditorContentError = !!errors?.[name];

    return (
      <Stack
        borderRadius={1}
        direction="column"
        gap={2}
        sx={{
          ...sx,
          ...(props.error &&
            !richEditorContentError && {
              border: '1px solid',
              borderColor: 'error.main',
            }),
        }}
      >
        <RichEditor
          {...props}
          initialValue={initialValue}
          placeholder={placeholder}
          ref={ref}
          sx={editorContentSx}
          toolbarEndComponent={
            typeof toolbarEndComponent !== 'undefined' ? (
              toolbarEndComponent
            ) : (
              <IconButton
                color="info"
                disabled={
                  !value ||
                  disabled ||
                  richEditorContentError ||
                  value === '<p><br></p>'
                }
                // TODO: Fix the "<p><br></p>" wacky validation
                size="small"
                sx={{ ml: 'auto' }}
                onClick={(event) => onSubmit(value, event)}
              >
                <SendIcon fontSize="small" />
              </IconButton>
            )
          }
          toolbarStartComponent={
            typeof toolbarStartComponent !== 'undefined' ? (
              toolbarStartComponent
            ) : (
              <LoggedUserAvatar sx={{ mr: 1 }} />
            )
          }
        />

        {props.error && !richEditorContentError && (
          <Typography sx={{ color: 'error.main' }}>
            {props.helperText}
          </Typography>
        )}
      </Stack>
    );
  },
);

export default UserInput;
