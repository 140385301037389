import React, { useRef } from 'react';
import { Stack } from '@mui/material';
import { Formik } from 'formik';
import { CLEAR_EDITOR_COMMAND } from 'lexical';

import Comment from 'src/components/v3/Comment';
import { useStudioId } from 'src/utils/hooks/useStudioId';
import Form, { CommentField } from 'src/components/ui-components/Form';

import {
  useCreateEventComment,
  useUpdateEventComment,
} from '@legalsurf/hooks/src/management';
import { useDeleteEventComment } from 'src/components/v3/Comment/utils';
import { EmptyComments } from './EmptyComments';
import { YupRichEditorContentValidation } from 'src/components/v3/RichEditor/utils';
import * as Yup from 'yup';
import dayjs from 'dayjs';
import { useUser } from 'src/utils/hooks/useUser';

const ValidationSchema = Yup.object({
  comment: YupRichEditorContentValidation,
});

const CommentSection = ({ event }) => {
  const studioId = useStudioId();
  const { user } = useUser();
  const richEditorRef = useRef(null);
  const [createEventComment] = useCreateEventComment({
    update: (cache, response) => {
      cache.modify({
        id: cache.identify(event),
        fields: {
          comments(comments, { toReference }) {
            return [
              ...comments,
              toReference(response.data.createEventComment, true),
            ];
          },
        },
      });
    },
  });

  const handleSubmitComment = (values, { resetForm, setSubmitting }) => {
    createEventComment({
      variables: {
        calendarId: event.calendarId,
        eventId: event.id,
        studioId,
        text: values.comment,
      },
      optimisticResponse: {
        createEventComment: {
          __typename: 'EventComment',
          id: 'mock_123123',
          text: values.comment,
          calendarId: event.calendarId,
          eventId: event.id,
          createdAt: dayjs().toISOString(),
          updatedAt: dayjs().toISOString(),
          studioId,
          createdBy: {
            id: user.id,
            name: user.name,
            picture: user.picture,
          },
        },
      },
    });

    if (richEditorRef.current) {
      const editor = richEditorRef.current;

      // NO TE VUELVAS LOCO MI PANA, TRANQUILO
      editor.dispatchCommand(CLEAR_EDITOR_COMMAND);
    }

    resetForm();
    setSubmitting(false);
  };

  const deleteEventComment = useDeleteEventComment();
  const [updateEventComment] = useUpdateEventComment();

  const handleUpdate = ({ commentId, content }) =>
    updateEventComment({
      variables: {
        commentId,
        calendarId: event.calendarId,
        eventId: event.id,
        studioId,
        text: content,
      },
    });

  return (
    <Stack gap={2} px={2}>
      <Stack
        gap={2}
        sx={{
          pt: 1,

          '& > *:not(:last-child)': {
            borderBottom: '1px dashed',
            borderColor: 'divider',
          },
        }}
      >
        {!event.comments?.length && <EmptyComments />}

        {event.comments?.map((comment) => (
          <Comment
            comment={comment}
            key={comment.id}
            onRemove={(commentId) =>
              deleteEventComment({
                variables: {
                  commentId,
                  calendarId: event.calendarId,
                  eventId: event.id,
                  studioId,
                },
              })
            }
            onUpdate={handleUpdate}
          />
        ))}
      </Stack>

      <Formik
        initialValues={{ comment: '' }}
        onSubmit={handleSubmitComment}
        validationSchema={ValidationSchema}
      >
        <Form id="commentForm">
          <CommentField
            name="comment"
            ref={richEditorRef}
            sx={{ bgcolor: 'background.input', p: 2, borderRadius: 0.5 }}
          />
        </Form>
      </Formik>
    </Stack>
  );
};

export default CommentSection;
