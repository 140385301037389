import React from 'react';
import { Box, Stack } from '@mui/material';

import useDigitalOceanSpace from 'src/utils/hooks/useDigitalOceanSpace';
import { File } from 'src/components/v2/FilesDropzone';
import { useDialog } from 'src/dialogs/Dialogs';
import { usePermissions } from 'src/utils/hooks/usePermissions';
import { LS_PERMISSIONS_MAP } from '@legalsurf/common';
import { CreationInfoBanner } from 'src/components/v3/CreationInfoBanner';

import { ContractTemplatePopover } from 'src/popovers/ContractTemplatePopover';
import { AutoFixHigh } from '@mui/icons-material';
import SingleFilecaseDocumentsErrorBoundary from './SingleFilecaseDocumentsErrorBoundary';

const SingleFilecaseDocuments = ({ filecase }) => {
  const dispatchWarningPromptDialog = useDialog('warningPrompt');
  const dispatchDocumentsFormDialog = useDialog('documentsForm');
  const dispatchContractPreviewDialog = useDialog('contractPreview');

  const [contractTemplateEl, setContractTemplateEl] = React.useState(null);

  const { hasPermissions: hasCreatePermissions } = usePermissions([
    LS_PERMISSIONS_MAP.DOCUMENTS.CREATE,
  ]);

  const { hasPermissions: hasDeletePermissions } = usePermissions([
    LS_PERMISSIONS_MAP.DOCUMENTS.UPDATE,
    LS_PERMISSIONS_MAP.FILECASES.UPDATE,
  ]);

  const { handleUnlinkDocument } = useDigitalOceanSpace();

  const handleRemove = (file) =>
    dispatchWarningPromptDialog('SingleFilecaseDocuments', {
      onAccept: () =>
        handleUnlinkDocument({
          documentId: file.id,
          filecaseId: filecase.id,
        }),
      title: 'Desvincular documento',
      content: `Estas seguro que deseas desvincular el documento ${file.name} de tu expediente? (Esta acción no eliminará el documento de LegalSurf)`,
    });

  const documentsFormOptions = {
    searchable: true,
    hideFilecase: true,
    disableDocuments: filecase.documents.map((val) => val.id),
    disableFields: {
      filecase: true,
    },
    file: {
      filecaseId: filecase.id,
      filecases: [{ value: filecase.id, label: filecase.title }],
    },
  };

  return (
    <SingleFilecaseDocumentsErrorBoundary>
      <Stack gap={2}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          {filecase.documents.map((document) => (
            <File
              document={document}
              downloable={document.kind === 'spaces'}
              key={document.id}
              previewable={document.kind === 'spaces'}
              {...(hasDeletePermissions && {
                onRemove: handleRemove,
              })}
            />
          ))}
        </Box>

        <ContractTemplatePopover
          anchorEl={contractTemplateEl}
          open={Boolean(contractTemplateEl)}
          onClick={(contract) =>
            dispatchContractPreviewDialog('SingleFilecaseHeader', {
              onClose: () => setContractTemplateEl(null),
              contract: {
                ...contract,
                filecaseId: filecase.id,
              },
              initialState: {
                filecase: {
                  data: filecase,
                },
              },
            })
          }
          onClose={() => setContractTemplateEl(null)}
        />

        {hasCreatePermissions && (
          <CreationInfoBanner
            buttonStr="Subir documentos"
            description="Sube archivos y adjuntalo a tu expediente."
            secondaryButton={
              hasCreatePermissions
                ? {
                    buttonStr: (
                      <Stack
                        alignItems="center"
                        direction="row"
                        gap={1}
                        justifyContent="center"
                      >
                        <AutoFixHigh sx={{ fontSize: 18 }} />
                        <Box>Generar escrito</Box>
                      </Stack>
                    ),
                    onClick: (event) =>
                      setContractTemplateEl(event.currentTarget),
                  }
                : undefined
            }
            title="Adjuntar documentos"
            onClick={() =>
              dispatchDocumentsFormDialog(
                'SingleFilecaseDocumentsForm',
                documentsFormOptions,
              )
            }
          />
        )}
      </Stack>
    </SingleFilecaseDocumentsErrorBoundary>
  );
};

export default SingleFilecaseDocuments;
