import React from 'react';
import { Stack } from '@mui/material';
import { useMutation } from '@apollo/client';

import { DELETE_ENTRY, UPDATE_ENTRY } from 'src/graphql/mutations/filecases';
import Comment from 'src/components/v3/Comment';
import { EmptyComments } from 'src/pages/Dashboard/Directories/DirectorySingle/DetailsColumn/Comments/EmptyComments';
import { useStudioId } from 'src/utils/hooks/useStudioId';
import dayjs from 'dayjs';
import { CommentForm } from './CommentForm';

export const SingleFilecaseEntries = ({ filecase }) => {
  const studioId = useStudioId();

  const [deleteEntry] = useMutation(DELETE_ENTRY, {
    update: (cache, result) => {
      cache.evict({ id: cache.identify(result.data.deleteEntry) });
    },
  });

  const [updateEntry] = useMutation(UPDATE_ENTRY);

  const handleCommentDelete = (commentId) =>
    deleteEntry({
      variables: {
        filecase: filecase.id,
        entry: commentId,
        studioId,
      },
    });

  const handleCommentUpdate = ({ commentId, content, date }) =>
    updateEntry({
      variables: {
        entry: commentId,
        filecase: filecase.id,
        studioId,
        content,
        date,
      },
    });

  return (
    <Stack gap={2}>
      <CommentForm filecase={filecase} />

      <Stack
        gap={2}
        sx={{
          pt: 1,
          '& > *:not(:last-child)': {
            borderBottom: '1px dashed',
            borderColor: 'divider',
          },
        }}
      >
        {filecase.entries?.map((entry) => (
          <Comment
            comment={entry}
            filesFormProps={{
              file: {
                filecaseId: filecase.id,
                filecases: [
                  {
                    label: filecase.title,
                    value: filecase.id,
                  },
                ],
              },
            }}
            formatComment={() => dayjs(entry.date).format('LL')}
            key={entry.id}
            onRemove={handleCommentDelete}
            onUpdate={handleCommentUpdate}
          />
        ))}
      </Stack>

      {!filecase.entries.length && <EmptyComments />}
    </Stack>
  );
};
