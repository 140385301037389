import React, { useRef } from 'react';

import Form, { CommentField } from 'src/components/ui-components/Form';
import { Formik } from 'formik';
import { useStudioId } from 'src/utils/hooks/useStudioId';
import { useMutation } from '@apollo/client';
import { CLEAR_EDITOR_COMMAND } from 'lexical';
import { CreateComment } from 'src/graphql/mutations';
import * as Yup from 'yup';
import { YupRichEditorContentValidation } from 'src/components/v3/RichEditor/utils';

const ValidationSchema = Yup.object({
  content: YupRichEditorContentValidation,
});

export const CommentForm = ({ entityId, entity }) => {
  const studioId = useStudioId();
  const richEditorRef = useRef(null);
  const [createComment] = useMutation(CreateComment, {
    update: (cache, result) => {
      cache.modify({
        id: cache.identify({
          __typename: 'Entity',
          id: result.data.createComment.entityId,
        }),
        fields: {
          comments(existingComments = []) {
            return [
              ...existingComments,
              { __ref: cache.identify(result.data.createComment) },
            ];
          },
        },
      });
    },
    onCompleted: () => {
      if (richEditorRef.current) {
        const editor = richEditorRef.current;

        // NO TE VUELVAS LOCO MI PANA, TRANQUILO
        editor.dispatchCommand(CLEAR_EDITOR_COMMAND);
      }
    },
  });

  const handleSubmitComment = async (values) => {
    await createComment({
      variables: {
        content: values.content,
        studioId,
        entityId,
      },
    });
  };

  const initialValues = {
    content: '',
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmitComment}
      validationSchema={ValidationSchema}
    >
      <Form id="commentForm">
        <CommentField
          filesFormProps={{
            file: {
              entityId,
              entities: [
                {
                  value: entity.id,
                  label: entity.title,
                },
              ],
            },
          }}
          name="content"
          ref={richEditorRef}
          sx={{ bgcolor: 'background.input', p: 2, borderRadius: 2 }}
        />
      </Form>
    </Formik>
  );
};
